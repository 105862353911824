import { Link, useLocation } from "react-router-dom"
import { useState, useEffect } from 'react';
import Resizer from "react-image-file-resizer";

import { loadUpdatePage, updateAssignmentDetails, updatePhoto01Details, updatePhoto02Details, updatePhoto03Details, updatePhoto04Details, updatePhoto05Details, updatePhoto06Details, updatePhoto07Details, updatePhoto08Details, updatePhoto09Details, updatePhoto10Details, updatePhoto11Details, updatePhoto12Details, updatePhoto13Details, updatePhoto14Details, updatePhoto15Details, updatePhoto16Details, updatePhoto17Details, updatePhoto18Details, updatePhoto19Details, updatePhoto20Details, updatePhoto21Details } from '../../Apis';
import { NotificationManager } from 'react-notifications';

import Footer from "../components/Footer"
import Header from "../components/Header"
import Sidebar from "../components/Sidebar"

const UpdateAssignment = () => {
	const { state } = useLocation();
	const { assignment } = state;

	const initialValues = [{ company: '', email: '', mobile: '', assign: '', kref: '', address: '', err: '' }];
	const warningValues = [{ photo01: false, photo02: false, photo03: false, photo04: false, photo05: false, photo06: false, photo07: false, photo08: false, photo09: false, photo10: false, photo11: false, photo12: false, photo13: false, photo14: false, photo15: false, photo16: false, photo17: false, photo18: false, photo19: false, photo20: false }];
	const [tabData, setTabData] = useState(1);
	const [errors, setErrors] = useState(initialValues);
	const [warning, setWarning] = useState(warningValues);
	const [loading, setLoading] = useState(false);
	const [photoLoading, setPhotoLoading] = useState(false);
	const [vdisplay, setVDisplay] = useState(1);

	const [company, setCompany] = useState('');
	const [email, setEmail] = useState('');
	const [mobile, setMobile] = useState('');
	const [assgnname, setAssigned] = useState('');
	const [kref, setKref] = useState('');
	const [address, setAddress] = useState('');
	const [paddress, setPAddress] = useState('');
	const [studiosize, setStudiosize] = useState('');
	const [typestudio, setTypestudio] = useState('');
	const [smokealarm, setSmokealarm] = useState('');
	const [overlays, setOverlays] = useState('');
	const [covenants, setCovenants] = useState('');
	const [caveats, setCaveats] = useState('');

	const [qust01, setQust01] = useState(2);
	const [qust02, setQust02] = useState(2);
	const [qust02img, setQust02img] = useState(null);
	const [qust03, setQust03] = useState(2);
	const [qust04, setQust04] = useState(2);
	const [qust05, setQust05] = useState(2);
	const [qust06, setQust06] = useState(2);
	const [qust07, setQust07] = useState(2);
	const [qust0801, setQust0801] = useState('');
	const [qust0802, setQust0802] = useState('');
	const [qust09, setQust09] = useState(2);
	const [qust09resn, setQust09Resn] = useState('');
	const [qust10, setQust10] = useState(2);
	const [qust10resn, setQust10Resn] = useState('');
	const [qust11, setQust11] = useState('');
	const [qust12, setQust12] = useState('');
	const [qust13, setQust13] = useState(0);
	const [qust13resn, setQust13Resn] = useState('');
	const [qust14, setQust14] = useState(2);
	const [qust15, setQust15] = useState('');
	const [qust16, setQust16] = useState(2);
	const [qust17, setQust17] = useState(2);
	const [qust18a, setQust18a] = useState('');
	const [qust18b, setQust18b] = useState('');
	const [qust18c, setQust18c] = useState('');
	const [qust18d, setQust18d] = useState('');
	const [qust18e, setQust18e] = useState('');
	const [qust18f, setQust18f] = useState('');

	const [photo01a, setPhoto01a] = useState(null);
	const [photo01b, setPhoto01b] = useState(null);
	const [photo01c, setPhoto01c] = useState(null);
	const [photo01d, setPhoto01d] = useState(null);
	const [photo02a, setPhoto02a] = useState(null);
	const [photo02b, setPhoto02b] = useState(null);
	const [photo02c, setPhoto02c] = useState(null);
	const [photo02d, setPhoto02d] = useState(null);
	const [photo03a, setPhoto03a] = useState(null);
	const [photo03b, setPhoto03b] = useState(null);
	const [photo03c, setPhoto03c] = useState(null);
	const [photo03d, setPhoto03d] = useState(null);
	const [photo04a, setPhoto04a] = useState(null);
	const [photo04b, setPhoto04b] = useState(null);
	const [photo04c, setPhoto04c] = useState(null);
	const [photo04d, setPhoto04d] = useState(null);
	const [photo05a, setPhoto05a] = useState(null);
	const [photo05b, setPhoto05b] = useState(null);
	const [photo05c, setPhoto05c] = useState(null);
	const [photo05d, setPhoto05d] = useState(null);
	const [photo06a, setPhoto06a] = useState(null);
	const [photo06b, setPhoto06b] = useState(null);
	const [photo06c, setPhoto06c] = useState(null);
	const [photo06d, setPhoto06d] = useState(null);
	const [photo07a, setPhoto07a] = useState(null);
	const [photo07b, setPhoto07b] = useState(null);
	const [photo07c, setPhoto07c] = useState(null);
	const [photo07d, setPhoto07d] = useState(null);
	const [photo08a, setPhoto08a] = useState(null);
	const [photo08b, setPhoto08b] = useState(null);
	const [photo08c, setPhoto08c] = useState(null);
	const [photo08d, setPhoto08d] = useState(null);
	const [photo09a, setPhoto09a] = useState(null);
	const [photo09b, setPhoto09b] = useState(null);
	const [photo09c, setPhoto09c] = useState(null);
	const [photo09d, setPhoto09d] = useState(null);
	const [photo10a, setPhoto10a] = useState(null);
	const [photo10b, setPhoto10b] = useState(null);
	const [photo10c, setPhoto10c] = useState(null);
	const [photo10d, setPhoto10d] = useState(null);
	const [photo11a, setPhoto11a] = useState(null);
	const [photo11b, setPhoto11b] = useState(null);
	const [photo11c, setPhoto11c] = useState(null);
	const [photo11d, setPhoto11d] = useState(null);
	const [photo12a, setPhoto12a] = useState(null);
	const [photo12b, setPhoto12b] = useState(null);
	const [photo12c, setPhoto12c] = useState(null);
	const [photo12d, setPhoto12d] = useState(null);
	const [photo13a, setPhoto13a] = useState(null);
	const [photo13b, setPhoto13b] = useState(null);
	const [photo13c, setPhoto13c] = useState(null);
	const [photo13d, setPhoto13d] = useState(null);
	const [photo14a, setPhoto14a] = useState(null);
	const [photo14b, setPhoto14b] = useState(null);
	const [photo14c, setPhoto14c] = useState(null);
	const [photo14d, setPhoto14d] = useState(null);
	const [photo15a, setPhoto15a] = useState(null);
	const [photo15b, setPhoto15b] = useState(null);
	const [photo15c, setPhoto15c] = useState(null);
	const [photo15d, setPhoto15d] = useState(null);
	const [photo16a, setPhoto16a] = useState(null);
	const [photo16b, setPhoto16b] = useState(null);
	const [photo16c, setPhoto16c] = useState(null);
	const [photo16d, setPhoto16d] = useState(null);
	const [photo17a, setPhoto17a] = useState(null);
	const [photo17b, setPhoto17b] = useState(null);
	const [photo17c, setPhoto17c] = useState(null);
	const [photo17d, setPhoto17d] = useState(null);
	const [photo18a, setPhoto18a] = useState(null);
	const [photo18b, setPhoto18b] = useState(null);
	const [photo18c, setPhoto18c] = useState(null);
	const [photo18d, setPhoto18d] = useState(null);
	const [photo19a, setPhoto19a] = useState(null);
	const [photo19b, setPhoto19b] = useState(null);
	const [photo19c, setPhoto19c] = useState(null);
	const [photo19d, setPhoto19d] = useState(null);
	const [photo20a, setPhoto20a] = useState(null);
	const [photo20b, setPhoto20b] = useState(null);
	const [photo20c, setPhoto20c] = useState(null);
	const [photo20d, setPhoto20d] = useState(null);
	const [photo21a, setPhoto21a] = useState(null);
	const [photo21b, setPhoto21b] = useState(null);
	const [photo21c, setPhoto21c] = useState(null);
	const [photo21d, setPhoto21d] = useState(null);

	const [cnfm01, setCnfm01] = useState(0);
	const [cnfm02, setCnfm02] = useState(0);
	const [cnfm03, setCnfm03] = useState(0);
	const [cnfm04, setCnfm04] = useState(0);
	const [cnfm05, setCnfm05] = useState(0);
	const [cnfm06, setCnfm06] = useState(0);
	const [cnfm07, setCnfm07] = useState(0);



	useEffect(() => {
		loadUpdatePage(assignment, (err, result) => {
			if (err) {
				NotificationManager.error(err.message, 'Failed to Load Information');
				return
			} else {
				setCompany(result.data[0].company);
				setEmail(result.data[0].email);
				setMobile(result.data[0].mobile);
				setAssigned(result.data[0].assgnname);
				setKref(result.data[0].kref);
				setAddress(result.data[0].generalloc);
				setPAddress(result.data[0].address);
				setStudiosize(result.data[0].studiosize);
				setTypestudio(result.data[0].type);
				setSmokealarm(result.data[0].smoke_alarm);
				setOverlays(result.data[0].overlay);
				setCovenants(result.data[0].covenants);
				setCaveats(result.data[0].caveats);

				setQust01(result.data[0].qust01);
				setQust02(result.data[0].qust02);
				setQust02img((result.data[0].qust02img == '') ? null : result.data[0].qust02img);
				setQust03(result.data[0].qust03);
				setQust04(result.data[0].qust04);
				setQust05(result.data[0].qust05);
				setQust06(result.data[0].qust06);
				setQust07(result.data[0].qust07);
				setQust0801(result.data[0].qust08a);
				setQust0802(result.data[0].qust08b);
				setQust09(result.data[0].qust09);
				setQust09Resn(result.data[0].qust09resn);
				setQust10(result.data[0].qust10);
				setQust10Resn(result.data[0].qust10resn);
				setQust11(result.data[0].qust11);
				setQust12(result.data[0].qust12);
				setQust13(result.data[0].qust13);
				setQust13Resn(result.data[0].qust13resn);
				setQust14(result.data[0].qust14);
				setQust15(result.data[0].qust15);
				setQust16(result.data[0].qust16);
				setQust17(result.data[0].qust17);
				setQust18a(result.data[0].qust18a);
				setQust18b(result.data[0].qust18b);
				setQust18c(result.data[0].qust18c);
				setQust18d(result.data[0].qust18d);
				setQust18e(result.data[0].qust18e);
				setQust18f(result.data[0].qust18f);

				setPhoto01a((result.data[0].item01a == '') ? null : result.data[0].item01a);
				setPhoto01b((result.data[0].item01b == '') ? null : result.data[0].item01b);
				setPhoto01c((result.data[0].item01c == '') ? null : result.data[0].item01c);
				setPhoto01d((result.data[0].item01d == '') ? null : result.data[0].item01d);
				setPhoto02a((result.data[0].item02a == '') ? null : result.data[0].item02a);
				setPhoto02b((result.data[0].item02b == '') ? null : result.data[0].item02b);
				setPhoto02c((result.data[0].item02c == '') ? null : result.data[0].item02c);
				setPhoto02d((result.data[0].item02d == '') ? null : result.data[0].item02d);
				setPhoto03a((result.data[0].item03a == '') ? null : result.data[0].item03a);
				setPhoto03b((result.data[0].item03b == '') ? null : result.data[0].item03b);
				setPhoto03c((result.data[0].item03c == '') ? null : result.data[0].item03c);
				setPhoto03d((result.data[0].item03d == '') ? null : result.data[0].item03d);
				setPhoto04a((result.data[0].item04a == '') ? null : result.data[0].item04a);
				setPhoto04b((result.data[0].item04b == '') ? null : result.data[0].item04b);
				setPhoto04c((result.data[0].item04c == '') ? null : result.data[0].item04c);
				setPhoto04d((result.data[0].item04d == '') ? null : result.data[0].item04d);
				setPhoto05a((result.data[0].item05a == '') ? null : result.data[0].item05a);
				setPhoto05b((result.data[0].item05b == '') ? null : result.data[0].item05b);
				setPhoto05c((result.data[0].item05c == '') ? null : result.data[0].item05c);
				setPhoto05d((result.data[0].item05d == '') ? null : result.data[0].item05d);
				setPhoto06a((result.data[0].item06a == '') ? null : result.data[0].item06a);
				setPhoto06b((result.data[0].item06b == '') ? null : result.data[0].item06b);
				setPhoto06c((result.data[0].item06c == '') ? null : result.data[0].item06c);
				setPhoto06d((result.data[0].item06d == '') ? null : result.data[0].item06d);
				setPhoto07a((result.data[0].item07a == '') ? null : result.data[0].item07a);
				setPhoto07b((result.data[0].item07b == '') ? null : result.data[0].item07b);
				setPhoto07c((result.data[0].item07c == '') ? null : result.data[0].item07c);
				setPhoto07d((result.data[0].item07d == '') ? null : result.data[0].item07d);
				setPhoto08a((result.data[0].item08a == '') ? null : result.data[0].item08a);
				setPhoto08b((result.data[0].item08b == '') ? null : result.data[0].item08b);
				setPhoto08c((result.data[0].item08c == '') ? null : result.data[0].item08c);
				setPhoto08d((result.data[0].item08d == '') ? null : result.data[0].item08d);
				setPhoto09a((result.data[0].item09a == '') ? null : result.data[0].item09a);
				setPhoto09b((result.data[0].item09b == '') ? null : result.data[0].item09b);
				setPhoto09c((result.data[0].item09c == '') ? null : result.data[0].item09c);
				setPhoto09d((result.data[0].item09d == '') ? null : result.data[0].item09d);
				setPhoto10a((result.data[0].item10a == '') ? null : result.data[0].item10a);
				setPhoto10b((result.data[0].item10b == '') ? null : result.data[0].item10b);
				setPhoto10c((result.data[0].item10c == '') ? null : result.data[0].item10c);
				setPhoto10d((result.data[0].item10d == '') ? null : result.data[0].item10d);
				setPhoto11a((result.data[0].item11a == '') ? null : result.data[0].item11a);
				setPhoto11b((result.data[0].item11b == '') ? null : result.data[0].item11b);
				setPhoto11c((result.data[0].item11c == '') ? null : result.data[0].item11c);
				setPhoto11d((result.data[0].item11d == '') ? null : result.data[0].item11d);
				setPhoto12a((result.data[0].item12a == '') ? null : result.data[0].item12a);
				setPhoto12b((result.data[0].item12b == '') ? null : result.data[0].item12b);
				setPhoto12c((result.data[0].item12c == '') ? null : result.data[0].item12c);
				setPhoto12d((result.data[0].item12d == '') ? null : result.data[0].item12d);
				setPhoto13a((result.data[0].item13a == '') ? null : result.data[0].item13a);
				setPhoto13b((result.data[0].item13b == '') ? null : result.data[0].item13b);
				setPhoto13c((result.data[0].item13c == '') ? null : result.data[0].item13c);
				setPhoto13d((result.data[0].item13d == '') ? null : result.data[0].item13d);
				setPhoto14a((result.data[0].item14a == '') ? null : result.data[0].item14a);
				setPhoto14b((result.data[0].item14b == '') ? null : result.data[0].item14b);
				setPhoto14c((result.data[0].item14c == '') ? null : result.data[0].item14c);
				setPhoto14d((result.data[0].item14d == '') ? null : result.data[0].item14d);
				setPhoto15a((result.data[0].item15a == '') ? null : result.data[0].item15a);
				setPhoto15b((result.data[0].item15b == '') ? null : result.data[0].item15b);
				setPhoto15c((result.data[0].item15c == '') ? null : result.data[0].item15c);
				setPhoto15d((result.data[0].item15d == '') ? null : result.data[0].item15d);
				setPhoto16a((result.data[0].item16a == '') ? null : result.data[0].item16a);
				setPhoto16b((result.data[0].item16b == '') ? null : result.data[0].item16b);
				setPhoto16c((result.data[0].item16c == '') ? null : result.data[0].item16c);
				setPhoto16d((result.data[0].item16d == '') ? null : result.data[0].item16d);
				setPhoto17a((result.data[0].item17a == '') ? null : result.data[0].item17a);
				setPhoto17b((result.data[0].item17b == '') ? null : result.data[0].item17b);
				setPhoto17c((result.data[0].item17c == '') ? null : result.data[0].item17c);
				setPhoto17d((result.data[0].item17d == '') ? null : result.data[0].item17d);
				setPhoto18a((result.data[0].item18a == '') ? null : result.data[0].item18a);
				setPhoto18b((result.data[0].item18b == '') ? null : result.data[0].item18b);
				setPhoto18c((result.data[0].item18c == '') ? null : result.data[0].item18c);
				setPhoto18d((result.data[0].item18d == '') ? null : result.data[0].item18d);
				setPhoto19a((result.data[0].item19a == '') ? null : result.data[0].item19a);
				setPhoto19b((result.data[0].item19b == '') ? null : result.data[0].item19b);
				setPhoto19c((result.data[0].item19c == '') ? null : result.data[0].item19c);
				setPhoto19d((result.data[0].item19d == '') ? null : result.data[0].item19d);
				setPhoto20a((result.data[0].item20a == '') ? null : result.data[0].item20a);
				setPhoto20b((result.data[0].item20b == '') ? null : result.data[0].item20b);
				setPhoto20c((result.data[0].item20c == '') ? null : result.data[0].item20c);
				setPhoto20d((result.data[0].item20d == '') ? null : result.data[0].item20d);
				setPhoto21a((result.data[0].item21a == '') ? null : result.data[0].item21a);
				setPhoto21b((result.data[0].item21b == '') ? null : result.data[0].item21b);
				setPhoto21c((result.data[0].item21c == '') ? null : result.data[0].item21c);
				setPhoto21d((result.data[0].item21d == '') ? null : result.data[0].item21d);

				setCnfm01(result.data[0].cnfm01);
				setCnfm02(result.data[0].cnfm02);
				setCnfm03(result.data[0].cnfm03);
				setCnfm04(result.data[0].cnfm04);
				setCnfm05(result.data[0].cnfm05);
				setCnfm06(result.data[0].cnfm06);
				setCnfm07(result.data[0].cnfm07);
			}
		});
	}, []);

	function changeTabData(value) {
		setTabData(value);
	}

	const handlePhoto01Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item01a'] = photo01a;
		data['item01b'] = photo01b;
		data['item01c'] = photo01c;
		data['item01d'] = photo01d;

		console.log(data);

		updatePhoto01Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 01', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto02Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item02a'] = photo02a;
		data['item02b'] = photo02b;
		data['item02c'] = photo02c;
		data['item02d'] = photo02d;

		console.log(data);

		updatePhoto02Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 02', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto03Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item03a'] = photo03a;
		data['item03b'] = photo03b;
		data['item03c'] = photo03c;
		data['item03d'] = photo03d;

		console.log(data);

		updatePhoto03Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 03', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto04Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item04a'] = photo04a;
		data['item04b'] = photo04b;
		data['item04c'] = photo04c;
		data['item04d'] = photo04d;

		console.log(data);

		updatePhoto04Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 04', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto05Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item05a'] = photo05a;
		data['item05b'] = photo05b;
		data['item05c'] = photo05c;
		data['item05d'] = photo05d;

		console.log(data);

		updatePhoto05Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 05', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto06Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item06a'] = photo06a;
		data['item06b'] = photo06b;
		data['item06c'] = photo06c;
		data['item06d'] = photo06d;

		console.log(data);

		updatePhoto06Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 06', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto07Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item07a'] = photo07a;
		data['item07b'] = photo07b;
		data['item07c'] = photo07c;
		data['item07d'] = photo07d;

		console.log(data);

		updatePhoto07Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 07', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto08Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item08a'] = photo08a;
		data['item08b'] = photo08b;
		data['item08c'] = photo08c;
		data['item08d'] = photo08d;

		console.log(data);

		updatePhoto08Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 08', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto09Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item09a'] = photo09a;
		data['item09b'] = photo09b;
		data['item09c'] = photo09c;
		data['item09d'] = photo09d;

		console.log(data);

		updatePhoto09Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 09', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto10Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item10a'] = photo10a;
		data['item10b'] = photo10b;
		data['item10c'] = photo10c;
		data['item10d'] = photo10d;

		console.log(data);

		updatePhoto10Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 10', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto11Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item11a'] = photo11a;
		data['item11b'] = photo11b;
		data['item11c'] = photo11c;
		data['item11d'] = photo11d;

		console.log(data);

		updatePhoto11Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 11', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto12Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item12a'] = photo12a;
		data['item12b'] = photo12b;
		data['item12c'] = photo12c;
		data['item12d'] = photo12d;

		console.log(data);

		updatePhoto12Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 12', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto13Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item13a'] = photo13a;
		data['item13b'] = photo13b;
		data['item13c'] = photo13c;
		data['item13d'] = photo13d;

		console.log(data);

		updatePhoto13Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 13', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto14Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item14a'] = photo14a;
		data['item14b'] = photo14b;
		data['item14c'] = photo14c;
		data['item14d'] = photo14d;

		console.log(data);

		updatePhoto14Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 14', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto15Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item15a'] = photo15a;
		data['item15b'] = photo15b;
		data['item15c'] = photo15c;
		data['item15d'] = photo15d;

		console.log(data);

		updatePhoto15Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 15', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto16Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item16a'] = photo16a;
		data['item16b'] = photo16b;
		data['item16c'] = photo16c;
		data['item16d'] = photo16d;

		console.log(data);

		updatePhoto16Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 16', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto17Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item17a'] = photo17a;
		data['item17b'] = photo17b;
		data['item17c'] = photo17c;
		data['item17d'] = photo17d;

		console.log(data);

		updatePhoto17Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 17', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto18Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item18a'] = photo18a;
		data['item18b'] = photo18b;
		data['item18c'] = photo18c;
		data['item18d'] = photo18d;

		console.log(data);

		updatePhoto18Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 18', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto19Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item19a'] = photo19a;
		data['item19b'] = photo19b;
		data['item19c'] = photo19c;
		data['item19d'] = photo19d;

		console.log(data);

		updatePhoto19Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 19', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto20Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item20a'] = photo20a;
		data['item20b'] = photo20b;
		data['item20c'] = photo20c;
		data['item20d'] = photo20d;

		console.log(data);

		updatePhoto20Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 20', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handlePhoto21Submit = (e) => {
		setPhotoLoading(true);
		let data = [];
		data['a_id'] = assignment;
		data['item21a'] = photo20a;
		data['item21b'] = photo20b;
		data['item21c'] = photo20c;
		data['item21d'] = photo20d;

		console.log(data);

		updatePhoto21Details(data, (err, result) => {
			if (err) {
				setPhotoLoading(false);
				return
			} else {
				NotificationManager.success('Have successfully updated the Images of Question 21', 'Images Updated');
				setPhotoLoading(false);
			}
		})
	}

	const handleSigninSubmit = (e) => {
		// e.preventDefault();
		let newErr = [...initialValues];
		let errflag = false;
		setErrors(newErr);
		// if (company === '') {
		// 	newErr['comapny'] = 'Please enter Company Name for Record';
		// 	errflag = true;
		// }
		let warnMessage = '';

		if (warning.photo01 === true) {
			warnMessage += 'Question 01, '
		}
		if (warning.photo02 === true) {
			warnMessage += 'Question 02, '
		}
		if (warning.photo03 === true) {
			warnMessage += 'Question 03, '
		}
		if (warning.photo04 === true) {
			warnMessage += 'Question 04, '
		}
		if (warning.photo05 === true) {
			warnMessage += 'Question 05, '
		}
		if (warning.photo06 === true) {
			warnMessage += 'Question 06, '
		}
		if (warning.photo07 === true) {
			warnMessage += 'Question 07, '
		}
		if (warning.photo08 === true) {
			warnMessage += 'Question 08, '
		}
		if (warning.photo09 === true) {
			warnMessage += 'Question 09, '
		}
		if (warning.photo10 === true) {
			warnMessage += 'Question 10, '
		}
		if (warning.photo11 === true) {
			warnMessage += 'Question 11, '
		}
		if (warning.photo12 === true) {
			warnMessage += 'Question 12, '
		}
		if (warning.photo13 === true) {
			warnMessage += 'Question 13, '
		}
		if (warning.photo14 === true) {
			warnMessage += 'Question 14, '
		}
		if (warning.photo15 === true) {
			warnMessage += 'Question 15, '
		}
		if (warning.photo16 === true) {
			warnMessage += 'Question 16, '
		}
		if (warning.photo17 === true) {
			warnMessage += 'Question 17, '
		}
		if (warning.photo18 === true) {
			warnMessage += 'Question 18, '
		}
		if (warning.photo19 === true) {
			warnMessage += 'Question 19, '
		}
		if (warning.photo20 === true) {
			warnMessage += 'Question 20, '
		}

		if (warnMessage.length > 0) {
			NotificationManager.warning(warnMessage, 'Photos Not Saved');
		}


		if (errflag === false) {
			setLoading(true);
			let data = [];
			data['a_id'] = assignment;
			data['paddress'] = paddress;
			data['studiosize'] = studiosize;
			data['typestudio'] = typestudio;
			data['smokealarm'] = smokealarm;
			data['overlays'] = overlays;
			data['covenants'] = covenants;
			data['caveats'] = caveats;
			data['qust01'] = qust01;
			data['qust02'] = qust02;
			data['qust02img'] = qust02img;
			data['qust03'] = qust03;
			data['qust04'] = qust04;
			data['qust05'] = qust05;
			data['qust06'] = qust06;
			data['qust07'] = qust07;
			data['qust08a'] = qust0801;
			data['qust08b'] = qust0802;
			data['qust09'] = qust09;
			data['qust09resn'] = qust09resn;
			data['qust10'] = qust10;
			data['qust10resn'] = qust10resn;
			data['qust11'] = qust11;
			data['qust12'] = qust12;
			data['qust13'] = qust13;
			data['qust13resn'] = qust13resn;
			data['qust14'] = qust14;
			data['qust15'] = qust15;
			data['qust16'] = qust16;
			data['qust17'] = qust17;
			data['qust18a'] = qust18a;
			data['qust18b'] = qust18b;
			data['qust18c'] = qust18c;
			data['qust18d'] = qust18d;
			data['qust18e'] = qust18e;
			data['qust18f'] = qust18f;

			data['cnfm01'] = cnfm01;
			data['cnfm02'] = cnfm02;
			data['cnfm03'] = cnfm03;
			data['cnfm04'] = cnfm04;
			data['cnfm05'] = cnfm05;
			data['cnfm06'] = cnfm06;
			data['cnfm07'] = cnfm07;

			console.log(data);

			updateAssignmentDetails(data, (err, result) => {
				if (err) {
					newErr['err'] = err.message;
					setLoading(false);
					setErrors(newErr);
					return
				} else {
					NotificationManager.success('Have successfully updated the assignment', 'Assignment Updated');
					setLoading(false);
				}
			})
		} else {
			setErrors(newErr);
		}
	}

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(file, 700, 700, "JPEG", 80, 0,
				(uri) => {
					resolve(uri);
				},
				"base64"
			);
		});

	const encodeImageFileAsURL = async (file) => {
		try {
			const cfile = file;
			const image = await resizeFile(cfile);
			setQust02img(image);
		} catch (err) {
			console.log(err);
		}
	};


	const encodeImageFile = async (inputFile, type) => {
		try {
			let newWarn = [...warning];
			const file = inputFile;
			const image = await resizeFile(file);
			if (type === '01a') {
				setPhoto01a(image);
				newWarn['photo01'] = true;
			} else if (type === '01b') {
				setPhoto01b(image);
				newWarn['photo01'] = true;
			} else if (type === '01c') {
				setPhoto01c(image);
				newWarn['photo01'] = true;
			} else if (type === '01d') {
				setPhoto01d(image);
				newWarn['photo01'] = true;
			} else if (type === '02a') {
				setPhoto02a(image);
				newWarn['photo02'] = true;
			} else if (type === '02b') {
				setPhoto02b(image);
				newWarn['photo02'] = true;
			} else if (type === '02c') {
				setPhoto02c(image);
				newWarn['photo02'] = true;
			} else if (type === '02d') {
				setPhoto02d(image);
				newWarn['photo02'] = true;
			} else if (type === '03a') {
				setPhoto03a(image);
				newWarn['photo03'] = true;
			} else if (type === '03b') {
				setPhoto03b(image);
				newWarn['photo03'] = true;
			} else if (type === '03c') {
				setPhoto03c(image);
				newWarn['photo03'] = true;
			} else if (type === '03d') {
				setPhoto03d(image);
				newWarn['photo03'] = true;
			} else if (type === '04a') {
				setPhoto04a(image);
				newWarn['photo04'] = true;
			} else if (type === '04b') {
				setPhoto04b(image);
				newWarn['photo04'] = true;
			} else if (type === '04c') {
				setPhoto04c(image);
				newWarn['photo04'] = true;
			} else if (type === '04d') {
				setPhoto04d(image);
				newWarn['photo04'] = true;
			} else if (type === '05a') {
				setPhoto05a(image);
				newWarn['photo05'] = true;
			} else if (type === '05b') {
				setPhoto05b(image);
				newWarn['photo05'] = true;
			} else if (type === '05c') {
				setPhoto05c(image);
				newWarn['photo05'] = true;
			} else if (type === '05d') {
				setPhoto05d(image);
				newWarn['photo05'] = true;
			} else if (type === '06a') {
				setPhoto06a(image);
				newWarn['photo06'] = true;
			} else if (type === '06b') {
				setPhoto06b(image);
				newWarn['photo06'] = true;
			} else if (type === '06c') {
				setPhoto06c(image);
				newWarn['photo06'] = true;
			} else if (type === '06d') {
				setPhoto06d(image);
				newWarn['photo06'] = true;
			} else if (type === '07a') {
				setPhoto07a(image);
				newWarn['photo07'] = true;
			} else if (type === '07b') {
				setPhoto07b(image);
				newWarn['photo07'] = true;
			} else if (type === '07c') {
				setPhoto07c(image);
				newWarn['photo07'] = true;
			} else if (type === '07d') {
				setPhoto07d(image);
				newWarn['photo07'] = true;
			} else if (type === '08a') {
				setPhoto08a(image);
				newWarn['photo08'] = true;
			} else if (type === '08b') {
				setPhoto08b(image);
				newWarn['photo08'] = true;
			} else if (type === '08c') {
				setPhoto08c(image);
				newWarn['photo08'] = true;
			} else if (type === '08d') {
				setPhoto08d(image);
				newWarn['photo08'] = true;
			} else if (type === '09a') {
				setPhoto09a(image);
				newWarn['photo09'] = true;
			} else if (type === '09b') {
				setPhoto09b(image);
				newWarn['photo09'] = true;
			} else if (type === '09c') {
				setPhoto09c(image);
				newWarn['photo09'] = true;
			} else if (type === '09d') {
				setPhoto09d(image);
				newWarn['photo09'] = true;
			} else if (type === '10a') {
				setPhoto10a(image);
				newWarn['photo10'] = true;
			} else if (type === '10b') {
				setPhoto10b(image);
				newWarn['photo10'] = true;
			} else if (type === '10c') {
				setPhoto10c(image);
				newWarn['photo10'] = true;
			} else if (type === '10d') {
				setPhoto10d(image);
				newWarn['photo10'] = true;
			} else if (type === '11a') {
				setPhoto11a(image);
				newWarn['photo11'] = true;
			} else if (type === '11b') {
				setPhoto11b(image);
				newWarn['photo11'] = true;
			} else if (type === '11c') {
				setPhoto11c(image);
				newWarn['photo11'] = true;
			} else if (type === '11d') {
				setPhoto11d(image);
				newWarn['photo11'] = true;
			} else if (type === '12a') {
				setPhoto12a(image);
				newWarn['photo12'] = true;
			} else if (type === '12b') {
				setPhoto12b(image);
				newWarn['photo12'] = true;
			} else if (type === '12c') {
				setPhoto12c(image);
				newWarn['photo12'] = true;
			} else if (type === '12d') {
				setPhoto12d(image);
				newWarn['photo12'] = true;
			} else if (type === '13a') {
				setPhoto13a(image);
				newWarn['photo13'] = true;
			} else if (type === '13b') {
				setPhoto13b(image);
				newWarn['photo13'] = true;
			} else if (type === '13c') {
				setPhoto13c(image);
				newWarn['photo13'] = true;
			} else if (type === '13d') {
				setPhoto13d(image);
				newWarn['photo13'] = true;
			} else if (type === '14a') {
				setPhoto14a(image);
				newWarn['photo14'] = true;
			} else if (type === '14b') {
				setPhoto14b(image);
				newWarn['photo14'] = true;
			} else if (type === '14c') {
				setPhoto14c(image);
				newWarn['photo14'] = true;
			} else if (type === '14d') {
				setPhoto14d(image);
				newWarn['photo14'] = true;
			} else if (type === '15a') {
				setPhoto15a(image);
				newWarn['photo15'] = true;
			} else if (type === '15b') {
				setPhoto15b(image);
				newWarn['photo15'] = true;
			} else if (type === '15c') {
				setPhoto15c(image);
				newWarn['photo15'] = true;
			} else if (type === '15d') {
				setPhoto15d(image);
				newWarn['photo15'] = true;
			} else if (type === '16a') {
				setPhoto16a(image);
				newWarn['photo16'] = true;
			} else if (type === '16b') {
				setPhoto16b(image);
				newWarn['photo16'] = true;
			} else if (type === '16c') {
				setPhoto16c(image);
				newWarn['photo16'] = true;
			} else if (type === '16d') {
				setPhoto16d(image);
				newWarn['photo16'] = true;
			} else if (type === '17a') {
				setPhoto17a(image);
				newWarn['photo17'] = true;
			} else if (type === '17b') {
				setPhoto17b(image);
				newWarn['photo17'] = true;
			} else if (type === '17c') {
				setPhoto17c(image);
				newWarn['photo17'] = true;
			} else if (type === '17d') {
				setPhoto17d(image);
				newWarn['photo17'] = true;
			} else if (type === '18a') {
				setPhoto18a(image);
				newWarn['photo18'] = true;
			} else if (type === '18b') {
				setPhoto18b(image);
				newWarn['photo18'] = true;
			} else if (type === '18c') {
				setPhoto18c(image);
				newWarn['photo18'] = true;
			} else if (type === '18d') {
				setPhoto18d(image);
				newWarn['photo18'] = true;
			} else if (type === '19a') {
				setPhoto19a(image);
				newWarn['photo19'] = true;
			} else if (type === '19b') {
				setPhoto19b(image);
				newWarn['photo19'] = true;
			} else if (type === '19c') {
				setPhoto19c(image);
				newWarn['photo19'] = true;
			} else if (type === '19d') {
				setPhoto19d(image);
				newWarn['photo19'] = true;
			} else if (type === '20a') {
				setPhoto20a(image);
				newWarn['photo20'] = true;
			} else if (type === '20b') {
				setPhoto20b(image);
				newWarn['photo20'] = true;
			} else if (type === '20c') {
				setPhoto20c(image);
				newWarn['photo20'] = true;
			} else if (type === '20d') {
				setPhoto20d(image);
				newWarn['photo20'] = true;
			} else if (type === '21a') {
				setPhoto21a(image);
				newWarn['photo21'] = true;
			} else if (type === '21b') {
				setPhoto21b(image);
				newWarn['photo21'] = true;
			} else if (type === '21c') {
				setPhoto21c(image);
				newWarn['photo21'] = true;
			} else if (type === '21d') {
				setPhoto21d(image);
				newWarn['photo21'] = true;
			}
			setWarning(newWarn);
		} catch (err) {
			console.log(err);
		}
	};

	const onChnageQTwo = (e) => {
		setQust02((e.target.value === 'yes') ? 1 : 0);
	}

	const onChnageQThree = (e) => {
		setQust03((e.target.value === 'yes') ? 1 : 0);
	}

	const onChnageQFour = (e) => {
		setQust04((e.target.value === 'yes') ? 1 : 0);
	}

	const onChnageQFive = (e) => {
		setQust05((e.target.value === 'yes') ? 1 : 0);
	}

	const onChnageQSix = (e) => {
		setQust06((e.target.value === 'yes') ? 1 : 0);
	}

	const onChnageQSeven = (e) => {
		setQust07((e.target.value === 'yes') ? 1 : 0);
	}

	const onChnageQNine = (e) => {
		setQust09((e.target.value === 'yes') ? 1 : 0);
	}

	const onChnageQTen = (e) => {
		setQust10((e.target.value === 'yes') ? 1 : 0);
	}

	const onChnageQTenFour = (e) => {

		setQust14((e.target.value === 'yes') ? 1 : 0);
	}

	const onChnageQTenSix = (e) => {
		setQust16((e.target.value === 'yes') ? 1 : 0);
	}

	const onChnageQTenSeven = (e) => {
		setQust17((e.target.value === 'yes') ? 1 : 0);
	}

	function openQuestion(e, question) {
		setVDisplay(question);
	}


	return (
		<>
			<Header />
			<Sidebar />
			<div className="content-wrapper">
				{/* Content Header (Page header) */}
				<section className="content-header">
					<div className="container-fluid">
						<div className="row mb-2">
							<div className="col-sm-6">
								<h1>Assignment Form</h1>
							</div>
							<div className="col-sm-6">
								<ol className="breadcrumb float-sm-right">
									<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
									<li className="breadcrumb-item active">Assignment Form</li>
								</ol>
							</div>
						</div>
					</div>{/* /.container-fluid */}
				</section>
				<section className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-12">
								<div className="card card-default">
									<div className="card-body p-0">

										<div className="bs-stepper">
											<div className="bs-stepper-header" role="tablist">
												<div className="step" data-target="#initial-part">
													<button type="button" className={(tabData === 1) ? 'onhover step-trigger' : 'step-trigger'} onClick={() => { changeTabData(1) }} role="tab" aria-controls="initial-part" id="initial-part-trigger">
														<span className="bs-stepper-circle">1</span>
														<span className="bs-stepper-label">Initial Info</span>
													</button>
												</div>
												<div className="line" />
												<div className="step" data-target="#information-basic">
													<button type="button" className={(tabData === 2) ? 'onhover step-trigger' : 'step-trigger'} onClick={() => { changeTabData(2) }} role="tab" aria-controls="information-basic" id="information-basic-trigger">
														<span className="bs-stepper-circle">2</span>
														<span className="bs-stepper-label">Basic Information</span>
													</button>
												</div>
												<div className="line" />
												<div className="step" data-target="#information-more">
													<button type="button" className={(tabData === 3) ? 'onhover step-trigger' : 'step-trigger'} onClick={() => { changeTabData(3) }} role="tab" aria-controls="information-more" id="information-more-trigger">
														<span className="bs-stepper-circle">3</span>
														<span className="bs-stepper-label">Checklist</span>
													</button>
												</div>
												<div className="line" />
												<div className="step" data-target="#information-photos">
													<button type="button" className={(tabData === 4) ? 'onhover step-trigger' : 'step-trigger'} onClick={() => { changeTabData(4) }} role="tab" aria-controls="information-photos" id="information-photos-trigger">
														<span className="bs-stepper-circle">4</span>
														<span className="bs-stepper-label">Photos</span>
													</button>
												</div>
												<div className="line" />
												<div className="step" data-target="#information-checkbox">
													<button type="button" className={(tabData === 5) ? 'onhover step-trigger' : 'step-trigger'} onClick={() => { changeTabData(5) }} role="tab" aria-controls="information-checkbox" id="information-checkbox-trigger">
														<span className="bs-stepper-circle">5</span>
														<span className="bs-stepper-label">Confirmation</span>
													</button>
												</div>
											</div>
											<div className="bs-stepper-content my-5">
												<div id="initial-part" className={(tabData !== 1) ? 'col-12 content' : 'col-12'} role="tabpanel" aria-labelledby="initial-part-trigger">
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="company">Company</label>
																<input type="text" className="form-control" defaultValue={company} readOnly={true} name="company" />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="email">Email</label>
																<input type="email" className="form-control" defaultValue={email} readOnly={true} name="email" />
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="mobile">Mobile</label>
																<input type="text" className="form-control" defaultValue={mobile} readOnly={true} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="user">Assigned Junior Architect</label>
																<input type="text" className="form-control" defaultValue={assgnname} readOnly={true} />
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="mobile">KREF</label>
																<input type="text" className="form-control" defaultValue={kref} readOnly={true} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="address">Proposed Studio Address</label>
																<input type="text" className="form-control" defaultValue={address} readOnly={true} />
															</div>
														</div>
													</div>
													<div className="text-right">
														<button className="btn btn-primary" onClick={() => { changeTabData(2) }}>Next</button>
													</div>
												</div>

												<div id="information-basic" className={(tabData !== 2) ? 'col-12 content' : 'col-12'} role="tabpanel" aria-labelledby="information-basic-trigger">
													<div className="row">
														<div className="col-md-12">
															<div className="form-group">
																<label>Home/Studio Address</label>
																<input type="text" className="form-control" value={paddress} onChange={e => setPAddress(e.target.value)} />
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label>Studio Size Proposed</label>
																<input type="text" className="form-control" value={studiosize} onChange={e => setStudiosize(e.target.value)} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label>Type of Studio</label>
																<input type="email" className="form-control" value={typestudio} onChange={e => setTypestudio(e.target.value)} />
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label>Interconnected Smoke Alarm</label>
																<input type="text" className="form-control" value={smokealarm} onChange={e => setSmokealarm(e.target.value)} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label>Overlays Identified</label>
																<input type="text" className="form-control" value={overlays} onChange={e => setOverlays(e.target.value)} />
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label>Covenants Identified</label>
																<input type="text" className="form-control" value={covenants} onChange={e => setCovenants(e.target.value)} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label>Caveats Identified</label>
																<input type="text" className="form-control" value={caveats} onChange={e => setCaveats(e.target.value)} />
															</div>
														</div>
													</div>
													<div className="text-right">
														<button className="btn btn-warning mr-2" onClick={() => { changeTabData(1) }}>Previous</button>
														<button className="btn btn-primary" onClick={() => { changeTabData(3) }}>Next</button>
													</div>
												</div>

												<div id="information-more" className={(tabData !== 3) ? 'col-12 content' : 'col-12'} role="tabpanel" aria-labelledby="information-more-trigger">
													<div className="form-group">
														<p className="lh-25">1.	To make a call to AOD office contact number 10 min before you reach site, so that carer is contacted and informed your arrival.</p>
														<div className="radio-group" onChange={e => setQust01((e.target.value === 'yes') ? 1 : 0)}>
															<input type="radio" id="question011" value="yes" name="radio-01" defaultChecked={qust01 == 1} />
															<label htmlFor="question011">Yes</label>
															<input type="radio" id="question012" value="no" name="radio-01" defaultChecked={qust01 == 0} />
															<label htmlFor="question012">No</label>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">2.	Site entry time recorded by front side image of the site (<i>time will taken at time of image upload)</i></p>
														<div className="radio-group" onChange={e => onChnageQTwo(e)}>
															<input type="radio" id="question021" value="yes" name="radio-02" defaultChecked={qust02 == 1} />
															<label htmlFor="question021">Yes</label>
															<input type="radio" id="question022" value="no" name="radio-02" defaultChecked={qust02 == 0} />
															<label htmlFor="question022">No</label>
														</div>

														<div className={"input-group " + (qust02 == 1 ? null : "d-none")} >
															<div className={(qust02img != null) ? null : "d-none"} >
																<img alt={qust02img + "not found"} className="upload-img" src={qust02img} />
																<button className="rmv-btn ml-20" onClick={(e) => { setQust02img(null) }}><i className="fa fa-times"></i></button>
															</div>
															<div className={(qust02img == null) ? null : "d-none"}>
																<div className="custom-file">
																	<input type="file" accept="image/*" className="custom-file-input" id="qust02File" onChange={(event) => {
																		encodeImageFileAsURL(event.target.files[0]);
																	}} />
																	<label className="btn btn-app custom-file-button" htmlFor="qust02File">
																		<i className="fa fa-camera"></i> Camera
																	</label>
																</div>
															</div>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">3.	Please confirm that you have followed the Covid checklist and mask is worn and distance was maintained at all times on site.</p>
														<div className="radio-group" onChange={e => onChnageQThree(e)}>
															<input type="radio" id="question031" value="yes" name="radio-03" defaultChecked={qust03 == 1} />
															<label htmlFor="question031">Yes</label>
															<input type="radio" id="question032" value="no" name="radio-03" defaultChecked={qust03 == 0} />
															<label htmlFor="question032">No</label>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">4.	Is carer present on site showing you access to rear garden (<i>Please call AOD if carer is not present and access door is shut</i>)</p>
														<div className="radio-group" onChange={e => onChnageQFour(e)}>
															<input type="radio" id="question041" value="yes" name="radio-04" defaultChecked={qust04 == 1} />
															<label htmlFor="question041">Yes</label>
															<input type="radio" id="question042" value="no" name="radio-04" defaultChecked={qust04 == 0} />
															<label htmlFor="question042">No</label>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">5.	Must introduce yourself and inform carer that you are here for Site planning for Kids Under cover studio/bunglow and will be assessing and measuring only outside premises for 1 hr (<i>Please call AOD if there is a trouble in communication</i>)</p>
														<div className="radio-group" onChange={e => onChnageQFive(e)}>
															<input type="radio" id="question051" value="yes" name="radio-05" defaultChecked={qust05 == 1} />
															<label htmlFor="question051">Yes</label>
															<input type="radio" id="question052" value="no" name="radio-05" defaultChecked={qust05 == 0} />
															<label htmlFor="question052">No</label>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">6.	Are the pets inside while you did the site measure?</p>
														<div className="radio-group" onChange={e => onChnageQSix(e)}>
															<input type="radio" id="question061" value="yes" name="radio-06" defaultChecked={qust06 == 1} />
															<label htmlFor="question061">Yes</label>
															<input type="radio" id="question062" value="no" name="radio-06" defaultChecked={qust06 == 0} />
															<label htmlFor="question062">No</label>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">7.	Please confirm that No contact has been made to Assets and items on site while measuring.</p>
														<div className="radio-group" onChange={e => onChnageQSeven(e)}>
															<input type="radio" id="question071" value="yes" name="radio-07" defaultChecked={qust07 == 1} />
															<label htmlFor="question071">Yes</label>
															<input type="radio" id="question072" value="no" name="radio-07" defaultChecked={qust07 == 0} />
															<label htmlFor="question072">No</label>
														</div>
													</div>

													<div className="row">
														<div className="col-md-12">
															<div className="form-group">
																<p>8.	Potential installation issues:<br /><br />
																	<i>Please list any issues identified by the carer or yourself, including concerns about household, safety, structures or impediments to site.</i></p>
																<textarea type="text" className="form-control" value={qust0801} onChange={e => setQust0801(e.target.value)} />
																<p className="mt-4"><i>If there is a structure or items that needs to be removed by the carer, please advise if this was discussed at the time of the appointment.</i></p>
																<textarea type="text" className="form-control" value={qust0802} onChange={e => setQust0802(e.target.value)} />

															</div>
														</div>
													</div>


													<div className="form-group">
														<p className="lh-25">9.	To fit in the studio location whether the clothesline needs to be relocated /removed ? and is the carer ready to remove the clothesline?</p>
														<div className="radio-group" onChange={e => onChnageQNine(e)}>
															<input type="radio" id="question091" value="yes" name="radio-09" defaultChecked={qust09 == 1} />
															<label htmlFor="question091">Yes</label>
															<input type="radio" id="question092" value="no" name="radio-09" defaultChecked={qust09 == 0} />
															<label htmlFor="question092">No</label>
															<textarea type="text" className={"form-control " + (qust09 == 0 ? null : "d-none")} value={qust09resn} onChange={e => setQust09Resn(e.target.value)} />
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">10.	Make sure to place the proposed studio without hindering any existing shed (if it is present on site) - <i>If No - List reasons why the shed needs removal and if it was discussed with carer and was asked if they are ready to remove the shed.</i></p>
														<div className="radio-group" onChange={e => onChnageQTen(e)}>
															<input type="radio" id="question101" value="yes" name="radio-10" defaultChecked={qust10 == 1} />
															<label htmlFor="question101">Yes</label>
															<input type="radio" id="question102" value="no" name="radio-10" defaultChecked={qust10 == 0} />
															<label htmlFor="question102">No</label>
															<textarea type="text" className={"form-control " + (qust10 === 0 ? null : "d-none")} value={qust10resn} onChange={e => setQust10Resn(e.target.value)} />
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">11.	Studio positioning relative to site overall:</p>
														<textarea type="text" className="form-control" value={qust11} onChange={e => setQust11(e.target.value)} />
													</div>

													<div className="form-group">
														<p className="lh-25">12.	Site Access:</p>
														<textarea type="text" className="form-control" value={qust12} onChange={e => setQust12(e.target.value)} />
													</div>

													<div className="form-group">
														<p className="lh-25">13.	Site fall:</p>
														<select className="form-control" defaultValue={qust13} onChange={e => setQust13(e.target.value)}>
															<option value={0} >Select Fall</option>
															<option value={1}>Relatively Flat</option>
															<option value={2}>Slight Slope</option>
															<option value={3}>Steep Slope</option>
														</select><br />
														<input type="text" className={"form-control " + ((qust13 == 2 || qust13 == 3) ? null : "d-none")} value={qust13resn} placeholder="Measurements" onChange={e => setQust13Resn(e.target.value)} />
													</div>

													<div className="form-group">
														<p className="lh-25">14.	Can the studio be craned in – either the kit off the truck or the full studio off a truck?</p>
														<div className="radio-group" onChange={e => onChnageQTenFour(e)}>
															<input type="radio" id="question141" value="yes" name="radio-14" defaultChecked={qust14 == 1} />
															<label htmlFor="question141">Yes</label>
															<input type="radio" id="question142" value="no" name="radio-14" defaultChecked={qust14 == 0} />
															<label htmlFor="question142">No</label>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">15.	Site main Access Width: (in mm)</p>
														<input type="text" className="form-control" value={qust15} onChange={e => setQust15(e.target.value)} />
													</div>

													<div className="form-group">
														<p className="lh-25">16.	Overhead power lines:</p>
														<div className="radio-group" onChange={e => onChnageQTenSix(e)}>
															<input type="radio" id="question161" value="yes" name="radio-16" defaultChecked={qust16 == 1} />
															<label htmlFor="question161">Yes</label>
															<input type="radio" id="question162" value="no" name="radio-16" defaultChecked={qust16 == 0} />
															<label htmlFor="question162">No</label>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">17.	Other overhead cables:</p>
														<div className="radio-group" onChange={e => onChnageQTenSeven(e)}>
															<input type="radio" id="question171" value="yes" name="radio-17" defaultChecked={qust17 == 1} />
															<label htmlFor="question171">Yes</label>
															<input type="radio" id="question172" value="no" name="radio-17" defaultChecked={qust17 == 0} />
															<label htmlFor="question172">No</label>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">18.	Services: Measurements from proposed location to be drawn and recorded in site plan</p>
														<input type="text" className="form-control" value={qust18a} onChange={e => setQust18a(e.target.value)} placeholder="Electrical Switchboard Location (in mm)" /><br />
														<input type="text" className="form-control" value={qust18b} onChange={e => setQust18b(e.target.value)} placeholder="Main Water Point Location (in mm)" /><br />
														<input type="text" className="form-control" value={qust18c} onChange={e => setQust18c(e.target.value)} placeholder="Sewer Point Location (in mm)" /><br />
														<input type="text" className="form-control" value={qust18d} onChange={e => setQust18d(e.target.value)} placeholder="Nearest Tap to Proposed Studio (in mm)" /><br />
														<input type="text" className="form-control" value={qust18e} onChange={e => setQust18e(e.target.value)} placeholder="Nearest Down Pipe (in mm)" /><br />
														<input type="text" className="form-control" value={qust18f} onChange={e => setQust18f(e.target.value)} placeholder="Hot Water Service (in mm)" />
													</div>


													<div className="text-right">
														<button className="btn btn-warning mr-2" onClick={() => { changeTabData(2) }}>Previous</button>
														<button className="btn btn-primary" onClick={() => { changeTabData(4) }}>Next</button>
													</div>
												</div>

												<div id="information-basic" className={(tabData !== 4) ? 'content' : null} role="tabpanel" aria-labelledby="information-basic-trigger">
													<div className="col-md-12">

														<div className="row">
															<div className="col-md-3 col-sm-2 col-xs-2 qsection">
																<div className="vtab-button-container">
																	<div className="row">
																		<div className={((vdisplay === 1) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 1) }}>
																			<span className="d-sm-below">Q 01</span>
																			<span className="d-sm-above">Question 01</span>
																		</div>
																		<div className={((vdisplay === 2) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 2) }}>
																			<span className="d-sm-below">Q 02</span>
																			<span className="d-sm-above">Question 02</span>
																		</div>
																		<div className={((vdisplay === 3) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 3) }}>
																			<span className="d-sm-below">Q 03</span>
																			<span className="d-sm-above">Question 03</span>
																		</div>
																		<div className={((vdisplay === 4) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 4) }}>
																			<span className="d-sm-below">Q 04</span>
																			<span className="d-sm-above">Question 04</span>
																		</div>
																		<div className={((vdisplay === 5) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 5) }}>
																			<span className="d-sm-below">Q 05</span>
																			<span className="d-sm-above">Question 05</span>
																		</div>
																		<div className={((vdisplay === 6) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 6) }}>
																			<span className="d-sm-below">Q 06</span>
																			<span className="d-sm-above">Question 06</span>
																		</div>
																		<div className={((vdisplay === 7) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 7) }}>
																			<span className="d-sm-below">Q 07</span>
																			<span className="d-sm-above">Question 07</span>
																		</div>
																		<div className={((vdisplay === 8) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 8) }}>
																			<span className="d-sm-below">Q 08</span>
																			<span className="d-sm-above">Question 08</span>
																		</div>
																		<div className={((vdisplay === 9) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 9) }}>
																			<span className="d-sm-below">Q 09</span>
																			<span className="d-sm-above">Question 09</span>
																		</div>
																		<div className={((vdisplay === 10) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 10) }}>
																			<span className="d-sm-below">Q 10</span>
																			<span className="d-sm-above">Question 10</span>
																		</div>
																		<div className={((vdisplay === 11) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 11) }}>
																			<span className="d-sm-below">Q 11</span>
																			<span className="d-sm-above">Question 11</span>
																		</div>
																		<div className={((vdisplay === 12) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 12) }}>
																			<span className="d-sm-below">Q 12</span>
																			<span className="d-sm-above">Question 12</span>
																		</div>
																		<div className={((vdisplay === 13) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 13) }}>
																			<span className="d-sm-below">Q 13</span>
																			<span className="d-sm-above">Question 13</span>
																		</div>
																		<div className={((vdisplay === 14) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 14) }}>
																			<span className="d-sm-below">Q 14</span>
																			<span className="d-sm-above">Question 14</span>
																		</div>
																		<div className={((vdisplay === 15) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 15) }}>
																			<span className="d-sm-below">Q 15</span>
																			<span className="d-sm-above">Question 15</span>
																		</div>
																		<div className={((vdisplay === 16) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 16) }}>
																			<span className="d-sm-below">Q 16</span>
																			<span className="d-sm-above">Question 16</span>
																		</div>
																		<div className={((vdisplay === 17) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 17) }}>
																			<span className="d-sm-below">Q 17</span>
																			<span className="d-sm-above">Question 17</span>
																		</div>
																		<div className={((vdisplay === 18) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 18) }}>
																			<span className="d-sm-below">Q 18</span>
																			<span className="d-sm-above">Question 18</span>
																		</div>
																		<div className={((vdisplay === 19) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 19) }}>
																			<span className="d-sm-below">Q 19</span>
																			<span className="d-sm-above">Question 19</span>
																		</div>
																		<div className={((vdisplay === 20) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 20) }}>
																			<span className="d-sm-below">Q 20</span>
																			<span className="d-sm-above">Question 20</span>
																		</div>
																		<div className={((vdisplay === 21) ? 'active' : '') + " vtab-button"} onClick={(e) => { openQuestion(e, 21) }}>
																			<span className="d-sm-below">Q 21</span>
																			<span className="d-sm-above">Question 21</span>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-md-9 col-sm-10 col-xs-10 psection">
																<div className="row">
																	<div className="vtab-content">
																		<div className={(vdisplay === 1) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">1) Street View photos for the build crew to see the access to the street and to the house</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo01a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo01a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto01a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo01a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo01aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '01a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo01aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo01b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo01b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto01b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo01b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo01bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '01b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo01bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo01c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo01c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto01c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo01c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo01cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '01c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo01cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo01d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo01d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto01d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo01d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo01dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '01d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo01dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto01Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>

																		</div>
																		<div className={(vdisplay === 2) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">2) Driveway</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo02a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo02a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto02a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo02a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo02aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '02a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo02aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo02b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo02b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto02b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo02b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo02bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '02b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo02bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo02c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo02c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto02c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo02c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo02cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '02c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo02cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo02d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo02d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto02d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo02d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo02dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '02d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo02dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto02Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																		<div className={(vdisplay === 3) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">3) Front or side access</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo03a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo03a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto03a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo03a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo03aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '03a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo03aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo03b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo03b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto03b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo03b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo03bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '03b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo03bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo03c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo03c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto03c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo03c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo03cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '03c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo03cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo03d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo03d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto03d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo03d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo03dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '03d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo03dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto03Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																		<div className={(vdisplay === 4) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">4) Garage</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo04a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo04a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto04a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo04a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo04aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '04a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo04aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo04b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo04b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto04b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo04b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo04bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '04b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo04bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo04c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo04c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto04c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo04c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo04cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '04c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo04cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo04d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo04d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto04d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo04d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo04dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '04d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo04dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto04Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>


																		<div className={(vdisplay === 5) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">5) Front garden/area photos</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo05a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo05a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto05a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo05a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo05aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '05a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo05aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo05b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo05b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto05b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo05b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo05bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '05b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo05bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo05c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo05c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto05c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo05c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo05cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '05c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo05cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo05d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo05d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto05d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo05d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo05dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '05d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo05dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto05Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>


																		<div className={(vdisplay === 6) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">6) Gate/Entry to rear yard</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo06a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo06a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto06a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo06a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo06aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '06a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo06aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo06b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo06b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto06b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo06b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo06bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '06b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo06bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo06c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo06c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto06c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo06c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo06cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '06c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo06cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo06d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo06d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto06d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo06d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo06dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '06d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo06dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto06Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																		<div className={(vdisplay === 7) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">7) All utilities location</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo07a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo07a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto07a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo07a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo07aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '07a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo07aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo07b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo07b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto07b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo07b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo07bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '07b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo07bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo07c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo07c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto07c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo07c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo07cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '07c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo07cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo07d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo07d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto07d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo07d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo07dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '07d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo07dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto07Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																		<div className={(vdisplay === 8) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">8) Electrical switchboard/ MSB location</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo08a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo08a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto08a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo08a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo08aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '08a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo08aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo08b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo08b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto08b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo08b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo08bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '08b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo08bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo08c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo08c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto08c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo08c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo08cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '08c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo08cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo08d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo08d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto08d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo08d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo08dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '08d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo08dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto08Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																		<div className={(vdisplay === 9) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">9) Main Water point location</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo09a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo09a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto09a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo09a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo09aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '09a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo09aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo09b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo09b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto09b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo09b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo09bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '09b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo09bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo09c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo09c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto09c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo09c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo09cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '09c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo09cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo09d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo09d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto09d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo09d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo09dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '09d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo09dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto09Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>


																		<div className={(vdisplay === 10) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">10) Sewer point and inspect shaft if visible on site location</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo10a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo10a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto10a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo10a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo10aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '10a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo10aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo10b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo10b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto10b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo10b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo10bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '10b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo10bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo10c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo10c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto10c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo10c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo10cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '10c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo10cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo10d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo10d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto10d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo10d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo10dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '10d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo10dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto10Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																		<div className={(vdisplay === 11) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">11) Nearest tap to proposed studio</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo11a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo11a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto11a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo11a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo11aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '11a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo11aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo11b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo11b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto11b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo11b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo11bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '11b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo11bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo11c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo11c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto11c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo11c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo11cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '11c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo11cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo11d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo11d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto11d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo11d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo11dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '11d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo11dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto11Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																		<div className={(vdisplay === 12) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">12) Nearest Down Pipe</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo12a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo12a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto12a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo12a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo12aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '12a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo12aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo12b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo12b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto12b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo12b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo12bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '12b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo12bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo12c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo12c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto12c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo12c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo12cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '12c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo12cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo12d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo12d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto12d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo12d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo12dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '12d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo12dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto12Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																		<div className={(vdisplay === 13) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">13) Hot water service</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo13a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo13a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto13a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo13a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo13aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '13a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo13aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo13b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo13b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto13b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo13b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo13bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '13b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo13bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo13c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo13c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto13c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo13c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo13cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '13c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo13cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo13d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo13d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto13d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo13d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo13dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '13d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo13dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto13Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																		<div className={(vdisplay === 14) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">14) PROPOSED STUDIO LOCATION</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo14a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo14a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto14a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo14a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo14aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '14a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo14aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo14b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo14b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto14b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo14b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo14bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '14b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo14bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo14c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo14c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto14c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo14c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo14cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '14c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo14cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo14d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo14d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto14d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo14d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo14dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '14d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo14dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto14Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																		<div className={(vdisplay === 15) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">15) Trees in rear yard (state if they need trimming. Or cutting down)</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo15a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo15a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto15a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo15a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo15aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '15a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo15aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo15b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo15b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto15b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo15b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo15bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '15b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo15bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo15c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo15c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto15c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo15c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo15cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '15c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo15cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo15d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo15d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto15d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo15d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo15dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '15d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo15dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto15Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																		<div className={(vdisplay === 16) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">16) Clothesline</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo16a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo16a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto16a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo16a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo16aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '16a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo16aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo16b != null) ? "d-row" : "d-none"} >
																								<img alt="not found photo 16b" className="upload-img d-cell w-100" src={photo16b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto16b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo16b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo16bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '16b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo16bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo16c != null) ? "d-row" : "d-none"} >
																								<img alt="not found photo16 c" className="upload-img d-cell w-100" src={photo16c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto16c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo16c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo16cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '16c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo16cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo16d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo16d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto16d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo16d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo16dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '16d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo16dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto16Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																		<div className={(vdisplay === 17) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">17) Rear yard photos from all sides</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo17a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo17a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto17a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo17a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo17aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '17a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo17aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo17b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo17b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto17b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo17b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo17bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '17b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo17bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo17c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo17c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto17c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo17c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo17cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '17c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo17cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo17d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo17d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto17d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo17d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo17dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '17d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo17dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto17Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																		<div className={(vdisplay === 18) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">18) Any sheds or storage areas at rear yard if applicable</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo18a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo18a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto18a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo18a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo18aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '18a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo18aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo18b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo18b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto18b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo18b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo18bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '18b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo18bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo18c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo18c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto18c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo18c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo18cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '18c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo18cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo18d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo18d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto18d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo18d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo18dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '18d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo18dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto18Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																		<div className={(vdisplay === 19) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">19) Side yard photos if applicable</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo19a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo19a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto19a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo19a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo19aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '19a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo19aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo19b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo19b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto19b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo19b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo19bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '19b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo19bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo19c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo19c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto19c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo19c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo19cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '19c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo19cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo19d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo19d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto19d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo19d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo19dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '19d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo19dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto19Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																		<div className={(vdisplay === 20) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">20) Side and back fences photos</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo20a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo20a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto20a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo20a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo20aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '20a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo20aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo20b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo20b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto20b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo20b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo20bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '20b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo20bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo20c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo20c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto20c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo20c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo20cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '20c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo20cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo20d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo20d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto20d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo20d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo20dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '20d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo20dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto20Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																		<div className={(vdisplay === 21) ? 'd-block' : 'd-none'}>
																			<div className="vtab-questions">21) Site Plan</div>

																			<div className="col-md-12">
																				<div className="row">
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo21a != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo21a} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto21a(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo21a == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo21aFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '21a');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo21aFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo21b != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo21b} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto21b(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo21b == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo21bFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '21b');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo21bFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo21c != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo21c} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto21c(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo21c == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo21cFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '21c');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo21cFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																					<div className="col-md-12 col-lg-6 mh-150">
																						<div className="d-contain">
																							<div className={(photo21d != null) ? "d-row" : "d-none"} >
																								<img alt="not found" className="upload-img d-cell w-100" src={photo21d} />
																								<div className="d-cell">
																									<button className="rmv-btn ml-20" onClick={(e) => { setPhoto21d(null) }}><i className="fa fa-times"></i></button>
																								</div>
																							</div>
																							<div className={(photo21d == null) ? null : "d-none"}>
																								<div className="custom-file">
																									<input type="file" accept="image/*" className="custom-file-input" id="photo21dFile" onChange={(event) => {
																										encodeImageFile(event.target.files[0], '21d');
																									}} />
																									<label className="btn btn-app custom-file-button" htmlFor="photo21dFile">
																										<i className="fa fa-camera"></i> Camera
																									</label>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>

																			<div className="vtab-footer">
																				<button type="submit" className="btn btn-primary" disabled={photoLoading ? 'disabled' : ''} onClick={handlePhoto21Submit}>{photoLoading && <i className="fas fa-spinner fa-spin"></i>} Save</button>
																			</div>
																		</div>

																	</div>
																</div>
															</div>
														</div>


													</div>

													<div className="text-right mt-20">
														<button className="btn btn-warning mr-2" onClick={() => { changeTabData(3) }}>Previous</button>
														<button className="btn btn-primary" onClick={() => { changeTabData(5) }}>Next</button>
													</div>
												</div>

												<div id="information-basic" className={(tabData !== 5) ? 'content' : null} role="tabpanel" aria-labelledby="information-basic-trigger">
													<div className="d-flex">
														<input type="checkbox" id="checkbox01" checked={cnfm01 == 1} onChange={(e) => { e.target.checked ? setCnfm01(1) : setCnfm01(0) }} className="regular-checkbox" />
														<label htmlFor="checkbox01">To maintain the carers confidentiality at all times however please raise any comments of concern you may have with AOD.</label>
													</div>

													<div className="d-flex">
														<input type="checkbox" id="checkbox02" checked={cnfm02 == 1} onChange={(e) => { e.target.checked ? setCnfm02(1) : setCnfm02(0) }} className="regular-checkbox" />
														<label htmlFor="checkbox02">Be professional to the carer all the times and no non work related conversations with carer or anyone else on the premises.</label>
													</div>

													<div className="d-flex">
														<input type="checkbox" id="checkbox03" checked={cnfm03 == 1} onChange={(e) => { e.target.checked ? setCnfm03(1) : setCnfm03(0) }} className="regular-checkbox" />
														<label htmlFor="checkbox03">Please ensure that the proposed studio is placed within 6 metres to the services/utilities to the property (where possible)Where it is not possible please measure the exact distance from all utilities to the proposed studio location in the sketch site plan.</label>
													</div>

													<div className="d-flex">
														<input type="checkbox" id="checkbox04" checked={cnfm04 == 1} onChange={(e) => { e.target.checked ? setCnfm04(1) : setCnfm04(0) }} className="regular-checkbox" />
														<label htmlFor="checkbox04">Please ensure that when considering the studio proposed location that the six-star energy rating is maintained.</label>
													</div>

													<div className="d-flex">
														<input type="checkbox" id="checkbox05" checked={cnfm05 == 1} onChange={(e) => { e.target.checked ? setCnfm05(1) : setCnfm05(0) }} className="regular-checkbox" />
														<label htmlFor="checkbox05">Please ensure that when considering the studio location, neighbouring fences and their heights is considered to ensure privacy is maintained for both parties</label>
													</div>

													<div className="d-flex">
														<input type="checkbox" id="checkbox06" checked={cnfm06 == 1} onChange={(e) => { e.target.checked ? setCnfm06(1) : setCnfm06(0) }} className="regular-checkbox" />
														<label htmlFor="checkbox06">Where possible, please orientate studio to maximise backyard space.</label>
													</div>

													<div className="d-flex">
														<input type="checkbox" id="checkbox07" checked={cnfm07 == 1} onChange={(e) => { e.target.checked ? setCnfm07(1) : setCnfm07(0) }} className="regular-checkbox" />
														<label htmlFor="checkbox07">For the studio positioning, Please check where the easement is running and as per regulation give enough setback.</label>
													</div>

													<div className="text-right">
														<button className="btn btn-warning mr-2" onClick={() => { changeTabData(4) }}>Previous</button>
														<button type="submit" className="btn btn-primary" disabled={loading ? 'disabled' : ''} onClick={handleSigninSubmit}>{loading && <i className="fas fa-spinner fa-spin"></i>} Submit</button>
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<Footer />
		</>
	)
}

export default UpdateAssignment