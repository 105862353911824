// const API_URL = 'http://localhost/ArkOfDesigns/';
const API_URL = 'https://app.arkofdesigns.com.au/source/';

export const loginUser = async (username, password, callback) => {
    fetch(API_URL + "login", {
        method: 'POST',
        body: JSON.stringify({
            uname: username,
            password: password
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const fetchDashboard = async (callback) => {
    fetch(API_URL + "get-dashboard", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const fetchAssignments = async (callback) => {
    fetch(API_URL + "get-assignment-details", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            userid: localStorage.getItem('aod_userid')
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const fetchUserList = async (callback) => {
    fetch(API_URL + "get-user-list", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updateUserFlag = async (userid, newflag, callback) => {
    fetch(API_URL + "update-user-flag", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            userid: userid,
            newflag: newflag
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const fetchUserData = async (callback) => {
    fetch(API_URL + "get-user-details", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const deleteAssignments = async (aid, callback) => {
    fetch(API_URL + "delete-assignment", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: aid
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const closeAssignments = async (aid, callback) => {
    fetch(API_URL + "close-assignment", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: aid
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const createUser = async (username, user, password, callback) => {
    fetch(API_URL + "create-user", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            username: username,
            name: user,
            password: password
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const createAssignment = async (company, email, mobile, assigned, kref, address, callback) => {
    fetch(API_URL + "create-assignment", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            company: company,
            email: email,
            mobile: mobile,
            assignedto: assigned,
            kref: kref,
            address: address
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto01Details = async (data, callback) => {
    fetch(API_URL + "update-photo01", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item01a: data.item01a,
            item01b: data.item01b,
            item01c: data.item01c,
            item01d: data.item01d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto02Details = async (data, callback) => {
    fetch(API_URL + "update-photo02", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item02a: data.item02a,
            item02b: data.item02b,
            item02c: data.item02c,
            item02d: data.item02d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto03Details = async (data, callback) => {
    fetch(API_URL + "update-photo03", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item03a: data.item03a,
            item03b: data.item03b,
            item03c: data.item03c,
            item03d: data.item03d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto04Details = async (data, callback) => {
    fetch(API_URL + "update-photo04", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item04a: data.item04a,
            item04b: data.item04b,
            item04c: data.item04c,
            item04d: data.item04d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto05Details = async (data, callback) => {
    fetch(API_URL + "update-photo05", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item05a: data.item05a,
            item05b: data.item05b,
            item05c: data.item05c,
            item05d: data.item05d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto06Details = async (data, callback) => {
    fetch(API_URL + "update-photo06", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item06a: data.item06a,
            item06b: data.item06b,
            item06c: data.item06c,
            item06d: data.item06d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto07Details = async (data, callback) => {
    fetch(API_URL + "update-photo07", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item07a: data.item07a,
            item07b: data.item07b,
            item07c: data.item07c,
            item07d: data.item07d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto08Details = async (data, callback) => {
    fetch(API_URL + "update-photo08", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item08a: data.item08a,
            item08b: data.item08b,
            item08c: data.item08c,
            item08d: data.item08d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto09Details = async (data, callback) => {
    fetch(API_URL + "update-photo09", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item09a: data.item09a,
            item09b: data.item09b,
            item09c: data.item09c,
            item09d: data.item09d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto10Details = async (data, callback) => {
    fetch(API_URL + "update-photo10", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item10a: data.item10a,
            item10b: data.item10b,
            item10c: data.item10c,
            item10d: data.item10d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto11Details = async (data, callback) => {
    fetch(API_URL + "update-photo11", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item11a: data.item11a,
            item11b: data.item11b,
            item11c: data.item11c,
            item11d: data.item11d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto12Details = async (data, callback) => {
    fetch(API_URL + "update-photo12", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item12a: data.item12a,
            item12b: data.item12b,
            item12c: data.item12c,
            item12d: data.item12d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto13Details = async (data, callback) => {
    fetch(API_URL + "update-photo13", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item13a: data.item13a,
            item13b: data.item13b,
            item13c: data.item13c,
            item13d: data.item13d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto14Details = async (data, callback) => {
    fetch(API_URL + "update-photo14", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item14a: data.item14a,
            item14b: data.item14b,
            item14c: data.item14c,
            item14d: data.item14d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto15Details = async (data, callback) => {
    fetch(API_URL + "update-photo15", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item15a: data.item15a,
            item15b: data.item15b,
            item15c: data.item15c,
            item15d: data.item15d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto16Details = async (data, callback) => {
    fetch(API_URL + "update-photo16", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item16a: data.item16a,
            item16b: data.item16b,
            item16c: data.item16c,
            item16d: data.item16d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto17Details = async (data, callback) => {
    fetch(API_URL + "update-photo17", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item17a: data.item17a,
            item17b: data.item17b,
            item17c: data.item17c,
            item17d: data.item17d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto18Details = async (data, callback) => {
    fetch(API_URL + "update-photo18", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item18a: data.item18a,
            item18b: data.item18b,
            item18c: data.item18c,
            item18d: data.item18d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto19Details = async (data, callback) => {
    fetch(API_URL + "update-photo19", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item19a: data.item19a,
            item19b: data.item19b,
            item19c: data.item19c,
            item19d: data.item19d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto20Details = async (data, callback) => {
    fetch(API_URL + "update-photo20", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item20a: data.item20a,
            item20b: data.item20b,
            item20c: data.item20c,
            item20d: data.item20d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updatePhoto21Details = async (data, callback) => {
    fetch(API_URL + "update-photo21", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            item21a: data.item21a,
            item21b: data.item21b,
            item21c: data.item21c,
            item21d: data.item21d,
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const updateAssignmentDetails = async (data, callback) => {
    fetch(API_URL + "update-assignment", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            aid: data.a_id,

            paddress: data.paddress,
            studiosize: data.studiosize,
            typestudio: data.typestudio,
            smokealarm: data.smokealarm,
            overlays: data.overlays,
            covenants: data.covenants,
            caveats: data.caveats,

            qust01: data.qust01,
            qust02: data.qust02,
            qust02img: data.qust02img,
            qust03: data.qust03,
            qust04: data.qust04,
            qust05: data.qust05,
            qust06: data.qust06,
            qust07: data.qust07,
            qust08a: data.qust08a,
            qust08b: data.qust08b,
            qust09: data.qust09,
            qust09resn: data.qust09resn,
            qust10: data.qust10,
            qust10resn: data.qust10resn,
            qust11: data.qust11,
            qust12: data.qust12,
            qust13: data.qust13,
            qust13resn: data.qust13resn,
            qust14: data.qust14,
            qust15: data.qust15,
            qust16: data.qust16,
            qust17: data.qust17,
            qust18a: data.qust18a,
            qust18b: data.qust18b,
            qust18c: data.qust18c,
            qust18d: data.qust18d,
            qust18e: data.qust18e,
            qust18f: data.qust18f,

            cnfm01: data.cnfm01,
            cnfm02: data.cnfm02,
            cnfm03: data.cnfm03,
            cnfm04: data.cnfm04,
            cnfm05: data.cnfm05,
            cnfm06: data.cnfm06,
            cnfm07: data.cnfm07,

            company: data.company,
            email: data.email,
            mobile: data.mobile,
            assignedto: data.assigned,
            kref: data.kref,
            address: data.address,

        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const loadPrintPage = async (assignment, callback) => {
    fetch(API_URL + "get-print-loadpage-details", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            assignment: assignment
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}

export const loadUpdatePage = async (assignment, callback) => {
    fetch(API_URL + "get-loadpage-details", {
        method: 'POST',
        headers: new Headers({
            'Content-type': 'application/json',
            'token': 'Bearer ' + localStorage.getItem('aod_token')
        }),
        body: JSON.stringify({
            assignment: assignment
        })
    }).then((response) => response.json())
        .then((data) => {
            console.log(data)
            callback(null, data);
        })
        .catch((error) => {
            console.log(error);
            callback(error, null);
        });
}
