import { Link, useLocation } from "react-router-dom"
import { useState, useEffect } from 'react';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import { loadPrintPage } from '../../Apis';
import { NotificationManager } from 'react-notifications';

import Footer from "../components/Footer"
import Header from "../components/Header"
import Sidebar from "../components/Sidebar"

const PrintAssignment = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'assignment-data',
    onAfterPrint: () => alert('print success')
  });

  const { state } = useLocation();
  const { assignment } = state;

  const [loading, setLoading] = useState(false);

  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [assgnname, setAssigned] = useState('');
  const [kref, setKref] = useState('');
  const [address, setAddress] = useState('');
  const [paddress, setPAddress] = useState('');
  const [studiosize, setStudiosize] = useState('');
  const [typestudio, setTypestudio] = useState('');
  const [smokealarm, setSmokealarm] = useState('');
  const [overlays, setOverlays] = useState('');
  const [covenants, setCovenants] = useState('');
  const [caveats, setCaveats] = useState('');

  const [qust01, setQust01] = useState(2);
  const [qust02, setQust02] = useState(2);
  const [qust02img, setQust02img] = useState(null);
  const [qust03, setQust03] = useState(2);
  const [qust04, setQust04] = useState(2);
  const [qust05, setQust05] = useState(2);
  const [qust06, setQust06] = useState(2);
  const [qust07, setQust07] = useState(2);
  const [qust0801, setQust0801] = useState('');
  const [qust0802, setQust0802] = useState('');
  const [qust09, setQust09] = useState(2);
  const [qust09resn, setQust09Resn] = useState('');
  const [qust10, setQust10] = useState(2);
  const [qust10resn, setQust10Resn] = useState('');
  const [qust11, setQust11] = useState('');
  const [qust12, setQust12] = useState('');
  const [qust13, setQust13] = useState(0);
  const [qust13resn, setQust13Resn] = useState('');
  const [qust14, setQust14] = useState(2);
  const [qust15, setQust15] = useState('');
  const [qust16, setQust16] = useState(2);
  const [qust17, setQust17] = useState(2);
  const [qust18a, setQust18a] = useState('');
  const [qust18b, setQust18b] = useState('');
  const [qust18c, setQust18c] = useState('');
  const [qust18d, setQust18d] = useState('');
  const [qust18e, setQust18e] = useState('');
  const [qust18f, setQust18f] = useState('');

  const [photo01a, setPhoto01a] = useState(null);
  const [photo01b, setPhoto01b] = useState(null);
  const [photo01c, setPhoto01c] = useState(null);
  const [photo01d, setPhoto01d] = useState(null);
  const [photo02a, setPhoto02a] = useState(null);
  const [photo02b, setPhoto02b] = useState(null);
  const [photo02c, setPhoto02c] = useState(null);
  const [photo02d, setPhoto02d] = useState(null);
  const [photo03a, setPhoto03a] = useState(null);
  const [photo03b, setPhoto03b] = useState(null);
  const [photo03c, setPhoto03c] = useState(null);
  const [photo03d, setPhoto03d] = useState(null);
  const [photo04a, setPhoto04a] = useState(null);
  const [photo04b, setPhoto04b] = useState(null);
  const [photo04c, setPhoto04c] = useState(null);
  const [photo04d, setPhoto04d] = useState(null);
  const [photo05a, setPhoto05a] = useState(null);
  const [photo05b, setPhoto05b] = useState(null);
  const [photo05c, setPhoto05c] = useState(null);
  const [photo05d, setPhoto05d] = useState(null);
  const [photo06a, setPhoto06a] = useState(null);
  const [photo06b, setPhoto06b] = useState(null);
  const [photo06c, setPhoto06c] = useState(null);
  const [photo06d, setPhoto06d] = useState(null);
  const [photo07a, setPhoto07a] = useState(null);
  const [photo07b, setPhoto07b] = useState(null);
  const [photo07c, setPhoto07c] = useState(null);
  const [photo07d, setPhoto07d] = useState(null);
  const [photo08a, setPhoto08a] = useState(null);
  const [photo08b, setPhoto08b] = useState(null);
  const [photo08c, setPhoto08c] = useState(null);
  const [photo08d, setPhoto08d] = useState(null);
  const [photo09a, setPhoto09a] = useState(null);
  const [photo09b, setPhoto09b] = useState(null);
  const [photo09c, setPhoto09c] = useState(null);
  const [photo09d, setPhoto09d] = useState(null);
  const [photo10a, setPhoto10a] = useState(null);
  const [photo10b, setPhoto10b] = useState(null);
  const [photo10c, setPhoto10c] = useState(null);
  const [photo10d, setPhoto10d] = useState(null);
  const [photo11a, setPhoto11a] = useState(null);
  const [photo11b, setPhoto11b] = useState(null);
  const [photo11c, setPhoto11c] = useState(null);
  const [photo11d, setPhoto11d] = useState(null);
  const [photo12a, setPhoto12a] = useState(null);
  const [photo12b, setPhoto12b] = useState(null);
  const [photo12c, setPhoto12c] = useState(null);
  const [photo12d, setPhoto12d] = useState(null);
  const [photo13a, setPhoto13a] = useState(null);
  const [photo13b, setPhoto13b] = useState(null);
  const [photo13c, setPhoto13c] = useState(null);
  const [photo13d, setPhoto13d] = useState(null);
  const [photo14a, setPhoto14a] = useState(null);
  const [photo14b, setPhoto14b] = useState(null);
  const [photo14c, setPhoto14c] = useState(null);
  const [photo14d, setPhoto14d] = useState(null);
  const [photo15a, setPhoto15a] = useState(null);
  const [photo15b, setPhoto15b] = useState(null);
  const [photo15c, setPhoto15c] = useState(null);
  const [photo15d, setPhoto15d] = useState(null);
  const [photo16a, setPhoto16a] = useState(null);
  const [photo16b, setPhoto16b] = useState(null);
  const [photo16c, setPhoto16c] = useState(null);
  const [photo16d, setPhoto16d] = useState(null);
  const [photo17a, setPhoto17a] = useState(null);
  const [photo17b, setPhoto17b] = useState(null);
  const [photo17c, setPhoto17c] = useState(null);
  const [photo17d, setPhoto17d] = useState(null);
  const [photo18a, setPhoto18a] = useState(null);
  const [photo18b, setPhoto18b] = useState(null);
  const [photo18c, setPhoto18c] = useState(null);
  const [photo18d, setPhoto18d] = useState(null);
  const [photo19a, setPhoto19a] = useState(null);
  const [photo19b, setPhoto19b] = useState(null);
  const [photo19c, setPhoto19c] = useState(null);
  const [photo19d, setPhoto19d] = useState(null);
  const [photo20a, setPhoto20a] = useState(null);
  const [photo20b, setPhoto20b] = useState(null);
  const [photo20c, setPhoto20c] = useState(null);
  const [photo20d, setPhoto20d] = useState(null);
  const [photo21a, setPhoto21a] = useState(null);
  const [photo21b, setPhoto21b] = useState(null);
  const [photo21c, setPhoto21c] = useState(null);
  const [photo21d, setPhoto21d] = useState(null);

  const [cnfm01, setCnfm01] = useState(0);
  const [cnfm02, setCnfm02] = useState(0);
  const [cnfm03, setCnfm03] = useState(0);
  const [cnfm04, setCnfm04] = useState(0);
  const [cnfm05, setCnfm05] = useState(0);
  const [cnfm06, setCnfm06] = useState(0);
  const [cnfm07, setCnfm07] = useState(0);



  useEffect(() => {
    setLoading(false)
    loadPrintPage(assignment, (err, result) => {
      if (err) {
        NotificationManager.error(err.message, 'Failed to Load Information');
        return
      } else {
        setCompany(result.data[0].company);
        setEmail(result.data[0].email);
        setMobile(result.data[0].mobile);
        setAssigned(result.data[0].assgnname);
        setKref(result.data[0].kref);
        setAddress(result.data[0].generalloc);
        setPAddress(result.data[0].address);
        setStudiosize(result.data[0].studiosize);
        setTypestudio(result.data[0].type);
        setSmokealarm(result.data[0].smoke_alarm);
        setOverlays(result.data[0].overlay);
        setCovenants(result.data[0].covenants);
        setCaveats(result.data[0].caveats);

        setQust01(result.data[0].qust01);
        setQust02(result.data[0].qust02);
        setQust02img((result.data[0].qust02img === '') ? null : result.data[0].qust02img);
        setQust03(result.data[0].qust03);
        setQust04(result.data[0].qust04);
        setQust05(result.data[0].qust05);
        setQust06(result.data[0].qust06);
        setQust07(result.data[0].qust07);
        setQust0801(result.data[0].qust08a);
        setQust0802(result.data[0].qust08b);
        setQust09(result.data[0].qust09);
        setQust09Resn(result.data[0].qust09resn);
        setQust10(result.data[0].qust10);
        setQust10Resn(result.data[0].qust10resn);
        setQust11(result.data[0].qust11);
        setQust12(result.data[0].qust12);
        setQust13(result.data[0].qust13);
        setQust13Resn(result.data[0].qust13resn);
        setQust14(result.data[0].qust14);
        setQust15(result.data[0].qust15);
        setQust16(result.data[0].qust16);
        setQust17(result.data[0].qust17);
        setQust18a(result.data[0].qust18a);
        setQust18b(result.data[0].qust18b);
        setQust18c(result.data[0].qust18c);
        setQust18d(result.data[0].qust18d);
        setQust18e(result.data[0].qust18e);
        setQust18f(result.data[0].qust18f);

        setPhoto01a((result.data[0].item01a === '') ? null : result.data[0].item01a);
        setPhoto01b((result.data[0].item01b === '') ? null : result.data[0].item01b);
        setPhoto01c((result.data[0].item01c === '') ? null : result.data[0].item01c);
        setPhoto01d((result.data[0].item01d === '') ? null : result.data[0].item01d);
        setPhoto02a((result.data[0].item02a === '') ? null : result.data[0].item02a);
        setPhoto02b((result.data[0].item02b === '') ? null : result.data[0].item02b);
        setPhoto02c((result.data[0].item02c === '') ? null : result.data[0].item02c);
        setPhoto02d((result.data[0].item02d === '') ? null : result.data[0].item02d);
        setPhoto03a((result.data[0].item03a === '') ? null : result.data[0].item03a);
        setPhoto03b((result.data[0].item03b === '') ? null : result.data[0].item03b);
        setPhoto03c((result.data[0].item03c === '') ? null : result.data[0].item03c);
        setPhoto03d((result.data[0].item03d === '') ? null : result.data[0].item03d);
        setPhoto04a((result.data[0].item04a === '') ? null : result.data[0].item04a);
        setPhoto04b((result.data[0].item04b === '') ? null : result.data[0].item04b);
        setPhoto04c((result.data[0].item04c === '') ? null : result.data[0].item04c);
        setPhoto04d((result.data[0].item04d === '') ? null : result.data[0].item04d);
        setPhoto05a((result.data[0].item05a === '') ? null : result.data[0].item05a);
        setPhoto05b((result.data[0].item05b === '') ? null : result.data[0].item05b);
        setPhoto05c((result.data[0].item05c === '') ? null : result.data[0].item05c);
        setPhoto05d((result.data[0].item05d === '') ? null : result.data[0].item05d);
        setPhoto06a((result.data[0].item06a === '') ? null : result.data[0].item06a);
        setPhoto06b((result.data[0].item06b === '') ? null : result.data[0].item06b);
        setPhoto06c((result.data[0].item06c === '') ? null : result.data[0].item06c);
        setPhoto06d((result.data[0].item06d === '') ? null : result.data[0].item06d);
        setPhoto07a((result.data[0].item07a === '') ? null : result.data[0].item07a);
        setPhoto07b((result.data[0].item07b === '') ? null : result.data[0].item07b);
        setPhoto07c((result.data[0].item07c === '') ? null : result.data[0].item07c);
        setPhoto07d((result.data[0].item07d === '') ? null : result.data[0].item07d);
        setPhoto08a((result.data[0].item08a === '') ? null : result.data[0].item08a);
        setPhoto08b((result.data[0].item08b === '') ? null : result.data[0].item08b);
        setPhoto08c((result.data[0].item08c === '') ? null : result.data[0].item08c);
        setPhoto08d((result.data[0].item08d === '') ? null : result.data[0].item08d);
        setPhoto09a((result.data[0].item09a === '') ? null : result.data[0].item09a);
        setPhoto09b((result.data[0].item09b === '') ? null : result.data[0].item09b);
        setPhoto09c((result.data[0].item09c === '') ? null : result.data[0].item09c);
        setPhoto09d((result.data[0].item09d === '') ? null : result.data[0].item09d);
        setPhoto10a((result.data[0].item10a === '') ? null : result.data[0].item10a);
        setPhoto10b((result.data[0].item10b === '') ? null : result.data[0].item10b);
        setPhoto10c((result.data[0].item10c === '') ? null : result.data[0].item10c);
        setPhoto10d((result.data[0].item10d === '') ? null : result.data[0].item10d);
        setPhoto11a((result.data[0].item11a === '') ? null : result.data[0].item11a);
        setPhoto11b((result.data[0].item11b === '') ? null : result.data[0].item11b);
        setPhoto11c((result.data[0].item11c === '') ? null : result.data[0].item11c);
        setPhoto11d((result.data[0].item11d === '') ? null : result.data[0].item11d);
        setPhoto12a((result.data[0].item12a === '') ? null : result.data[0].item12a);
        setPhoto12b((result.data[0].item12b === '') ? null : result.data[0].item12b);
        setPhoto12c((result.data[0].item12c === '') ? null : result.data[0].item12c);
        setPhoto12d((result.data[0].item12d === '') ? null : result.data[0].item12d);
        setPhoto13a((result.data[0].item13a === '') ? null : result.data[0].item13a);
        setPhoto13b((result.data[0].item13b === '') ? null : result.data[0].item13b);
        setPhoto13c((result.data[0].item13c === '') ? null : result.data[0].item13c);
        setPhoto13d((result.data[0].item13d === '') ? null : result.data[0].item13d);
        setPhoto14a((result.data[0].item14a === '') ? null : result.data[0].item14a);
        setPhoto14b((result.data[0].item14b === '') ? null : result.data[0].item14b);
        setPhoto14c((result.data[0].item14c === '') ? null : result.data[0].item14c);
        setPhoto14d((result.data[0].item14d === '') ? null : result.data[0].item14d);
        setPhoto15a((result.data[0].item15a === '') ? null : result.data[0].item15a);
        setPhoto15b((result.data[0].item15b === '') ? null : result.data[0].item15b);
        setPhoto15c((result.data[0].item15c === '') ? null : result.data[0].item15c);
        setPhoto15d((result.data[0].item15d === '') ? null : result.data[0].item15d);
        setPhoto16a((result.data[0].item16a === '') ? null : result.data[0].item16a);
        setPhoto16b((result.data[0].item16b === '') ? null : result.data[0].item16b);
        setPhoto16c((result.data[0].item16c === '') ? null : result.data[0].item16c);
        setPhoto16d((result.data[0].item16d === '') ? null : result.data[0].item16d);
        setPhoto17a((result.data[0].item17a === '') ? null : result.data[0].item17a);
        setPhoto17b((result.data[0].item17b === '') ? null : result.data[0].item17b);
        setPhoto17c((result.data[0].item17c === '') ? null : result.data[0].item17c);
        setPhoto17d((result.data[0].item17d === '') ? null : result.data[0].item17d);
        setPhoto18a((result.data[0].item18a === '') ? null : result.data[0].item18a);
        setPhoto18b((result.data[0].item18b === '') ? null : result.data[0].item18b);
        setPhoto18c((result.data[0].item18c === '') ? null : result.data[0].item18c);
        setPhoto18d((result.data[0].item18d === '') ? null : result.data[0].item18d);
        setPhoto19a((result.data[0].item19a === '') ? null : result.data[0].item19a);
        setPhoto19b((result.data[0].item19b === '') ? null : result.data[0].item19b);
        setPhoto19c((result.data[0].item19c === '') ? null : result.data[0].item19c);
        setPhoto19d((result.data[0].item19d === '') ? null : result.data[0].item19d);
        setPhoto20a((result.data[0].item20a === '') ? null : result.data[0].item20a);
        setPhoto20b((result.data[0].item20b === '') ? null : result.data[0].item20b);
        setPhoto20c((result.data[0].item20c === '') ? null : result.data[0].item20c);
        setPhoto20d((result.data[0].item20d === '') ? null : result.data[0].item20d);
        setPhoto21a((result.data[0].item21a === '') ? null : result.data[0].item21a);
        setPhoto21b((result.data[0].item21b === '') ? null : result.data[0].item21b);
        setPhoto21c((result.data[0].item21c === '') ? null : result.data[0].item21c);
        setPhoto21d((result.data[0].item21d === '') ? null : result.data[0].item21d);

        setCnfm01(result.data[0].cnfm01);
        setCnfm02(result.data[0].cnfm02);
        setCnfm03(result.data[0].cnfm03);
        setCnfm04(result.data[0].cnfm04);
        setCnfm05(result.data[0].cnfm05);
        setCnfm06(result.data[0].cnfm06);
        setCnfm07(result.data[0].cnfm07);
        setLoading(true)
      }
    });
  }, []);

  return (
    <>
      <Header />
      <Sidebar />
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Assignment Form</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                  <li className="breadcrumb-item active">Assignment Form</li>
                </ol>
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-default">
                  {(!loading) ? (
                    <div className="card-body">
                      <p className="text-center">
                        Loading...
                      </p>
                    </div>) : (
                    <div className="card-body">
                      <div className="d-flex width-100 justify-content-end pb-6">
                        <button
                          onClick={handlePrint}
                          type="button"
                          className="btn btn-primary mb-4"
                        >
                          Print PDF & Save
                        </button>
                      </div>
                      <div ref={componentRef} className="w-full p-4">
                        <table className="table w-100">
                          <tbody>
                            <tr>
                              <td colSpan={3}><b>Initial Information</b></td>
                            </tr>
                            <tr>
                              <td>Company</td>
                              <td>:</td>
                              <td>{company}</td>
                            </tr>
                            <tr>
                              <td>Email</td>
                              <td>:</td>
                              <td>{email}</td>
                            </tr>
                            <tr>
                              <td>Mobile</td>
                              <td>:</td>
                              <td>{mobile}</td>
                            </tr>
                            <tr>
                              <td>Assigned Junior Architect</td>
                              <td>:</td>
                              <td>{assgnname}</td>
                            </tr>
                            <tr>
                              <td>KREF</td>
                              <td>:</td>
                              <td>{kref}</td>
                            </tr>
                            <tr>
                              <td>Proposed Studio Address</td>
                              <td>:</td>
                              <td>{address}</td>
                            </tr>
                            <tr>
                              <td colSpan={3}><br /></td>
                            </tr>
                            <tr>
                              <td colSpan={3}><b>Basic Function</b></td>
                            </tr>
                            <tr>
                              <td>Home/Studio Address</td>
                              <td>:</td>
                              <td>{paddress}</td>
                            </tr>
                            <tr>
                              <td>Studio Size Proposed</td>
                              <td>:</td>
                              <td>{studiosize}</td>
                            </tr>
                            <tr>
                              <td>Type of Studio</td>
                              <td>:</td>
                              <td>{typestudio}</td>
                            </tr>
                            <tr>
                              <td>Interconnected Smoke Alarm</td>
                              <td>:</td>
                              <td>{smokealarm}</td>
                            </tr>
                            <tr>
                              <td>Overlays Identified</td>
                              <td>:</td>
                              <td>{overlays}</td>
                            </tr>
                            <tr>
                              <td>Covenants Identified</td>
                              <td>:</td>
                              <td>{covenants}</td>
                            </tr>
                            <tr>
                              <td>Caveats Identified</td>
                              <td>:</td>
                              <td>{caveats}</td>
                            </tr>
                          </tbody>
                        </table>
                        <br /><br />

                        <table className="table w-100">
                          <tbody>
                            <tr>
                              <td>1.	To make a call to AOD office contact number 10 min before you reach site, so that carer is contacted and informed your arrival.
                                <br /><br />
                                {(qust01 === '1') ? 'Yes' : 'No'}
                              </td>
                            </tr>
                            <tr>
                              <td>2.	Site entry time recorded by front side image of the site <i>(time will taken at time of image upload)</i>
                                <br /><br />
                                {(qust02 === '1') ? 'Yes' : 'No'}
                                <br />
                                {qust02img != null ? (
                                  <img alt={qust02img + "not found"} className="upload-img" src={qust02img} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>3.	Please confirm that you have followed the Covid checklist and mask is worn and distance was maintained at all times on site.
                                <br /><br />
                                {(qust03 === '1') ? 'Yes' : 'No'}
                              </td>
                            </tr>
                            <tr>
                              <td>4.	Is carer present on site showing you access to rear garden (<i>Please call AOD if carer is not present and access door is shut</i>)
                                <br /><br />
                                {(qust04 === '1') ? 'Yes' : 'No'}
                              </td>
                            </tr>
                            <tr>
                              <td>5.	Must introduce yourself and inform carer that you are here for Site planning for Kids Under cover studio/bunglow and will be assessing and measuring only outside premises for 1 hr (<i>Please call AOD if there is a trouble in communication</i>)
                                <br /><br />
                                {(qust05 === '1') ? 'Yes' : 'No'}
                              </td>
                            </tr>
                            <tr>
                              <td>6.	Are the pets inside while you did the site measure?
                                <br /><br />
                                {(qust06 === '1') ? 'Yes' : 'No'}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                7.	Please confirm that No contact has been made to Assets and items on site while measuring.
                                <br /><br />
                                {(qust07 === '1') ? 'Yes' : 'No'}
                              </td>
                            </tr>
                            <tr>
                              <td>8 a).	Potential installation issues:<br /><br />
                                <i>Please list any issues identified by the carer or yourself, including concerns about household, safety, structures or impediments to site.</i>
                                <br /><br />
                                {qust0801}
                              </td>
                            </tr>
                            <tr>
                              <td>8 b). <i>If there is a structure or items that needs to be removed by the carer, please advise if this was discussed at the time of the appointment.</i>
                                <br /><br />
                                {qust0802}
                              </td>
                            </tr>
                            <tr>
                              <td>9.	To fit in the studio location whether the clothesline needs to be relocated /removed ? and is the carer ready to remove the clothesline?
                                <br /><br />
                                {(qust09 === '1') ? 'Yes' : 'No'}
                                <br />
                                {qust09resn}
                              </td>
                            </tr>
                            <tr>
                              <td>10.	Make sure to place the proposed studio without hindering any existing shed (if it is present on site) - <i>If No - List reasons why the shed needs removal and if it was discussed with carer and was asked if they are ready to remove the shed.</i>
                                <br /><br />
                                {(qust10 === '1') ? 'Yes' : 'No'}
                                <br />
                                {qust10resn}
                              </td>
                            </tr>
                            <tr>
                              <td>11.	Studio positioning relative to site overall:
                                <br /><br />
                                {qust11}
                              </td>
                            </tr>
                            <tr>
                              <td>12.	Site Access:
                                <br /><br />
                                {qust12}
                              </td>
                            </tr>
                            <tr>
                              <td>13.	Site fall:
                                <br /><br />
                                {qust13 === '1' ? 'Relatively Flat' : (qust13 === '2' ? 'Slight Slope' : 'Steep Slope')}
                                <br />
                                {qust13resn}
                              </td>
                            </tr>
                            <tr>
                              <td>14.	Can the studio be craned in – either the kit off the truck or the full studio off a truck?
                                <br /><br />
                                {(qust14 === '1') ? 'Yes' : 'No'}
                              </td>
                            </tr>
                            <tr>
                              <td>15.	Site main Access Width: (in mm)
                                <br /><br />
                                {qust15}
                              </td>
                            </tr>
                            <tr>
                              <td>16.	Overhead power lines:
                                <br /><br />
                                {(qust16 === '1') ? 'Yes' : 'No'}
                              </td>
                            </tr>
                            <tr>
                              <td>17.	Other overhead cables:
                                <br /><br />
                                {(qust17 === '1') ? 'Yes' : 'No'}
                              </td>
                            </tr>
                            <tr>
                              <td>18.	Services: Measurements from proposed location to be drawn and recorded in site plan
                                <br /><br />
                                Electrical Switchboard Location (in mm) : {qust18a} <br />
                                Main Water Point Location (in mm) : {qust18b} <br />
                                Sewer Point Location (in mm) : {qust18c} <br />
                                Nearest Tap to Proposed Studio (in mm): {qust18d} <br />
                                Nearest Down Pipe (in mm) : {qust18e} <br />
                                Hot Water Service (in mm): {qust18f}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table className="table w-100">
                          <tbody>
                            <tr>
                              <td>
                                1) Street View photos for the build crew to see the access to the street and to the house
                                <br /><br />
                                {photo01a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo01a} /><br /></>
                                ) : null}
                                {photo01b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo01b} />
                                  <br /></>
                                ) : null}
                                {photo01c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo01c} />
                                  <br /></>
                                ) : null}
                                {photo01d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo01d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                2) Driveway
                                <br /><br />
                                {photo02a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo02a} /><br /></>
                                ) : null}
                                {photo02b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo02b} />
                                  <br /></>
                                ) : null}
                                {photo02c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo02c} />
                                  <br /></>
                                ) : null}
                                {photo02d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo02d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                3) Front or side access
                                <br /><br />
                                {photo03a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo03a} /><br /></>
                                ) : null}
                                {photo03b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo03b} />
                                  <br /></>
                                ) : null}
                                {photo03c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo03c} />
                                  <br /></>
                                ) : null}
                                {photo03d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo03d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                4) Garage
                                <br /><br />
                                {photo04a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo04a} /><br /></>
                                ) : null}
                                {photo04b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo04b} />
                                  <br /></>
                                ) : null}
                                {photo04c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo04c} />
                                  <br /></>
                                ) : null}
                                {photo04d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo04d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                5) Front garden/area photos
                                <br /><br />
                                {photo05a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo05a} /><br /></>
                                ) : null}
                                {photo05b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo05b} />
                                  <br /></>
                                ) : null}
                                {photo05c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo05c} />
                                  <br /></>
                                ) : null}
                                {photo05d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo05d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                6) Gate/Entry to rear yard
                                <br /><br />
                                {photo06a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo06a} /><br /></>
                                ) : null}
                                {photo06b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo06b} />
                                  <br /></>
                                ) : null}
                                {photo06c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo06c} />
                                  <br /></>
                                ) : null}
                                {photo06d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo06d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                7) All utilities location
                                <br /><br />
                                {photo07a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo07a} /><br /></>
                                ) : null}
                                {photo07b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo07b} />
                                  <br /></>
                                ) : null}
                                {photo07c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo07c} />
                                  <br /></>
                                ) : null}
                                {photo07d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo07d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                8) Electrical switchboard/ MSB location
                                <br /><br />
                                {photo08a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo08a} /><br /></>
                                ) : null}
                                {photo08b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo08b} />
                                  <br /></>
                                ) : null}
                                {photo08c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo08c} />
                                  <br /></>
                                ) : null}
                                {photo08d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo08d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                9) Main Water point location
                                <br /><br />
                                {photo09a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo09a} /><br /></>
                                ) : null}
                                {photo09b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo09b} />
                                  <br /></>
                                ) : null}
                                {photo09c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo09c} />
                                  <br /></>
                                ) : null}
                                {photo09d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo09d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                10) Sewer point and inspect shaft if visible on site location
                                <br /><br />
                                {photo10a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo10a} /><br /></>
                                ) : null}
                                {photo10b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo10b} />
                                  <br /></>
                                ) : null}
                                {photo10c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo10c} />
                                  <br /></>
                                ) : null}
                                {photo10d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo10d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                11) Nearest tap to proposed studio
                                <br /><br />
                                {photo11a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo11a} /><br /></>
                                ) : null}
                                {photo11b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo11b} />
                                  <br /></>
                                ) : null}
                                {photo11c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo11c} />
                                  <br /></>
                                ) : null}
                                {photo11d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo11d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                12) Nearest Down Pipe
                                <br /><br />
                                {photo12a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo12a} /><br /></>
                                ) : null}
                                {photo12b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo12b} />
                                  <br /></>
                                ) : null}
                                {photo12c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo12c} />
                                  <br /></>
                                ) : null}
                                {photo12d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo12d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                13) Hot water service
                                <br /><br />
                                {photo13a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo13a} /><br /></>
                                ) : null}
                                {photo13b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo13b} />
                                  <br /></>
                                ) : null}
                                {photo13c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo13c} />
                                  <br /></>
                                ) : null}
                                {photo13d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo13d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                14) PROPOSED STUDIO LOCATION
                                <br /><br />
                                {photo14a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo14a} /><br /></>
                                ) : null}
                                {photo14b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo14b} />
                                  <br /></>
                                ) : null}
                                {photo14c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo14c} />
                                  <br /></>
                                ) : null}
                                {photo14d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo14d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                15) Trees in rear yard (state if they need trimming. Or cutting down)
                                <br /><br />
                                {photo15a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo15a} /><br /></>
                                ) : null}
                                {photo15b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo15b} />
                                  <br /></>
                                ) : null}
                                {photo15c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo15c} />
                                  <br /></>
                                ) : null}
                                {photo15d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo15d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                16) Clothesline
                                <br /><br />
                                {photo16a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo16a} /><br /></>
                                ) : null}
                                {photo16b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo16b} />
                                  <br /></>
                                ) : null}
                                {photo16c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo16c} />
                                  <br /></>
                                ) : null}
                                {photo16d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo16d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                17) Rear yard photos from all sides
                                <br /><br />
                                {photo17a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo17a} /><br /></>
                                ) : null}
                                {photo17b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo17b} />
                                  <br /></>
                                ) : null}
                                {photo17c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo17c} />
                                  <br /></>
                                ) : null}
                                {photo17d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo17d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                18) Any sheds or storage areas at rear yard if applicable
                                <br /><br />
                                {photo18a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo18a} /><br /></>
                                ) : null}
                                {photo18b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo18b} />
                                  <br /></>
                                ) : null}
                                {photo18c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo18c} />
                                  <br /></>
                                ) : null}
                                {photo18d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo18d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                19) Side yard photos if applicable
                                <br /><br />
                                {photo19a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo19a} /><br /></>
                                ) : null}
                                {photo19b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo19b} />
                                  <br /></>
                                ) : null}
                                {photo19c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo19c} />
                                  <br /></>
                                ) : null}
                                {photo19d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo19d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                20) Side and back fences photos
                                <br /><br />
                                {photo20a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo20a} /><br /></>
                                ) : null}
                                {photo20b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo20b} />
                                  <br /></>
                                ) : null}
                                {photo20c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo20c} />
                                  <br /></>
                                ) : null}
                                {photo20d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo20d} />) : null}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                21) Site Plan photos
                                <br /><br />
                                {photo21a != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo21a} /><br /></>
                                ) : null}
                                {photo21b != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo21b} />
                                  <br /></>
                                ) : null}
                                {photo21c != null ? (<>
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo21c} />
                                  <br /></>
                                ) : null}
                                {photo21d != null ? (
                                  <img alt="not found" className="upload-img d-cell w-100" src={photo21d} />) : null}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table className="table w-100">
                          <tbody>
                            <tr>
                              <td>To maintain the carers confidentiality at all times however please raise any comments of concern you may have with AOD.</td>
                              <td>:</td>
                              <td>{cnfm01 === '1' ? 'Verified ' : 'Unverified'}</td>
                            </tr>
                            <tr>
                              <td>Be professional to the carer all the times and no non work related conversations with carer or anyone else on the premises.</td>
                              <td>:</td>
                              <td>{cnfm02 === '1' ? 'Verified ' : 'Unverified'}</td>
                            </tr>
                            <tr>
                              <td>Please ensure that the proposed studio is placed within 6 metres to the services/utilities to the property (where possible)Where it is not possible please measure the exact distance from all utilities to the proposed studio location in the sketch site plan.</td>
                              <td>:</td>
                              <td>{cnfm03 === '1' ? 'Verified ' : 'Unverified'}</td>
                            </tr>
                            <tr>
                              <td>Please ensure that when considering the studio proposed location that the six-star energy rating is maintained.</td>
                              <td>:</td>
                              <td>{cnfm04 === '1' ? 'Verified ' : 'Unverified'}</td>
                            </tr>
                            <tr>
                              <td>Please ensure that when considering the studio location, neighboring fences and their heights is considered to ensure privacy is maintained for both parties</td>
                              <td>:</td>
                              <td>{cnfm05 === '1' ? 'Verified ' : 'Unverified'}</td>
                            </tr>
                            <tr>
                              <td>Where possible, please orientate studio to maximize backyard space.</td>
                              <td>:</td>
                              <td>{cnfm06 === '1' ? 'Verified ' : 'Unverified'}</td>
                            </tr>
                            <tr>
                              <td>For the studio positioning, Please check where the easement is running and as per regulation give enough setback.</td>
                              <td>:</td>
                              <td>{cnfm07 === '1' ? 'Verified ' : 'Unverified'}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  )
}

export default PrintAssignment