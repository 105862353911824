import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Preloading from './components/Preloading';
import CreateUser from './pages/create-user/CreateUser';
import PrintAssignment from './pages/print-assignment/PrintAssignment';
import UpdateAssignment from './pages/update-assignment/UpdateAssignment';
import UserList from './pages/user-list/UserList';
import ViewAssignment from './pages/view-assignment/ViewAssignment';

const PageNotFound = lazy(() => import('./components/PageNotFound'));
const Login = lazy(() => import('./pages/login/Login'));
const Home = lazy(() => import('./pages/home/Home'));
const Assignments = lazy(() => import('./pages/assignments/Assignments'));
const CreateAssignment = lazy(() => import('./pages/create-assignment/CreateAssignment'));

function App() {
	function RequireAuth({ children }) {
		let authenticated = (localStorage.getItem('aod_token') !== null) ? true : false;
		return authenticated === true ? children : <Navigate to="/" replace />;
	}

	return (
		<Router>
			<Suspense fallback={<Preloading />}>
				<Routes>
					<Route path='/dashboard' element={<RequireAuth><Home /></RequireAuth>} />
					<Route path='/edit-assignment' element={<RequireAuth><UpdateAssignment /></RequireAuth>} />
					<Route path='/assignments' element={<RequireAuth><Assignments /></RequireAuth>} />
					<Route path='/create-assignments' element={<RequireAuth><CreateAssignment /></RequireAuth>} />
					<Route path='/user-list' element={<RequireAuth><UserList /></RequireAuth>} />
					<Route path='/create-user' element={<RequireAuth><CreateUser /></RequireAuth>} />
					<Route path='/view-assignment' element={<RequireAuth><ViewAssignment /></RequireAuth>} />
					<Route path='/pdf-assignment' element={<RequireAuth><PrintAssignment /></RequireAuth>} />
					<Route path='/' element={<Login />} />

					<Route path='*' element={<PageNotFound />} />
				</Routes>
			</Suspense>
		</Router>
	);
}

export default App;