import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { fetchUserList, updateUserFlag } from '../../Apis';
import { NotificationManager } from 'react-notifications';

import Footer from "../components/Footer"
import Header from "../components/Header"
import Sidebar from "../components/Sidebar"


const UserList = () => {
	const [users, setUsers] = useState([]);

	const navigate = useNavigate();

	useEffect(() => {
		fetchUserList((err, result) => {
			if (err) {
				alert(err.message)
				return
			} else {
				setUsers(result.data.users);
			}
		});
	}, []);



	const editStatus = (userid) => {
		let user = [...users];
		for (let i = 0; i < user.length; i++) {
			if (user[i].user_id == userid) {
				let newval = user[i].flag == 0 ? '1' : '0';
				user[i].flag = (newval);
				updateUserFlag(user[i].user_id, newval, (err, result) => {
					if (err) {
						alert(err.message)
						return
					} else {
						NotificationManager.success('User status have been successfully updated', 'User Status Updated');
					}
				});
				break;
			}
		}
		setUsers(user);
	}

	return (
		<>
			<Header />
			<Sidebar />
			<div className="content-wrapper">
				{/* Content Header (Page header) */}
				<section className="content-header">
					<div className="container-fluid">
						<div className="row mb-2">
							<div className="col-sm-6">
								<h1>User List</h1>
							</div>
							<div className="col-sm-6">
								<ol className="breadcrumb float-sm-right">
									<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
									<li className="breadcrumb-item active">User List</li>
								</ol>
							</div>
						</div>
					</div>{/* /.container-fluid */}
				</section>
				{/* Main content */}
				<section className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12">
								<div className="card">
									<div className="card-header">
										<div className="row">
											<div className="col-md-9">
												<h3 className="card-title">User List</h3>
											</div>
											<div className="col-md-3">
												<Link to="/create-user"><button type="button" className="btn btn-block btn-primary btn-sm">Create New User</button></Link>
											</div>
										</div>
									</div>
									{/* /.card-header */}
									<div className="card-body">
										<div className="overflow-auto">
											<table id="example2" className="table table-bordered table-hover table-striped">
												<thead>
													<tr>
														<th>#</th>
														<th>User</th>
														<th>Username</th>
														<th>Status</th>
														<th>Last Login</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{users.map((data, index) => (
														<tr key={data.user_id}>
															<td>{index + 1}</td>
															<td>{data.name}</td>
															<td>{data.username}</td>
															<td>{(data.flag == '1') ? 'Activate' : 'In-activate'}</td>
															<td>{(data.lastlogin != null) ? (new Date(data.lastlogin)).toLocaleDateString() : 'NA'}</td>
															<td>{(data.flag == '0') ?
																(<button type="button" className="btn btn-block btn-success btn-xs" onClick={() => editStatus(data.user_id)}>Activate User</button>
																) :
																(<button type="button" className="btn btn-block btn-warning btn-xs" onClick={() => editStatus(data.user_id)}>Deactivate User</button>)
															}</td>
														</tr>
													))}
												</tbody>
												<tfoot>
													<tr>
														<th>#</th>
														<th>User</th>
														<th>Username</th>
														<th>Status</th>
														<th>Last Login</th>
														<th>Action</th>
													</tr>
												</tfoot>
											</table>
										</div>
									</div>
									{/* /.card-body */}
								</div>
							</div>
							{/* /.col */}
						</div>
						{/* /.row */}
					</div>
					{/* /.container-fluid */}
				</section>
				{/* /.content */}
			</div>

			<Footer />
		</>
	)
}

export default UserList