import './Preloading.css';

import loading from './loader.gif';

const Preloading = () => {
    return (
        <>
            <div className="loading">
                <img className="loadimg" src={loading} alt="" /> Loading...
            </div>
        </>
    )
}

export default Preloading
