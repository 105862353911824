import { useEffect, useState } from 'react';
import { NotificationContainer } from 'react-notifications';

const Footer = () => {
	const [twoDigitYear, setTwoDigitYear] = useState('');

	useEffect(() => {
		var strDate = new Date(); // By default Date empty constructor give you Date.now
		var shortYear = strDate.getFullYear();
		// Add this line
		setTwoDigitYear(shortYear.toString().substr(-2));
	}, []);

	return (
		<>
			<NotificationContainer />
			<footer className="main-footer">
				<span>Copyright © 2022-{twoDigitYear} <a href="#" target="_blank">Ark of Design</a>. </span>
				All rights reserved.
				<div className="float-right d-none d-sm-inline-block">
					<b>Version</b> 1.0.1 | Powered by <a href="https://empyef.com" target="_blank">Empyef Technologies</a>
				</div>
			</footer>
		</>
	)
}

export default Footer