import React from 'react'
import { Link, useLocation } from "react-router-dom"
import { useState, useEffect } from 'react';

import { loadUpdatePage } from '../../Apis';
import { NotificationManager } from 'react-notifications';

import Footer from "../components/Footer"
import Header from "../components/Header"
import Sidebar from "../components/Sidebar"


const ViewAssignment = () => {
	const { state } = useLocation();
	const { assignment } = state;
	const [tabData, setTabData] = useState(1);

	const [company, setCompany] = useState('');
	const [email, setEmail] = useState('');
	const [mobile, setMobile] = useState('');
	const [assgnname, setAssigned] = useState('');
	const [kref, setKref] = useState('');
	const [address, setAddress] = useState('');
	const [paddress, setPAddress] = useState('');
	const [studiosize, setStudiosize] = useState('');
	const [typestudio, setTypestudio] = useState('');
	const [smokealarm, setSmokealarm] = useState('');
	const [overlays, setOverlays] = useState('');
	const [covenants, setCovenants] = useState('');
	const [caveats, setCaveats] = useState('');

	const [qust01, setQust01] = useState(2);
	const [qust02, setQust02] = useState(2);
	const [qust02img, setQust02img] = useState(null);
	const [qust03, setQust03] = useState(2);
	const [qust04, setQust04] = useState(2);
	const [qust05, setQust05] = useState(2);
	const [qust06, setQust06] = useState(2);
	const [qust07, setQust07] = useState(2);
	const [qust0801, setQust0801] = useState('');
	const [qust0802, setQust0802] = useState('');
	const [qust09, setQust09] = useState(2);
	const [qust09resn, setQust09Resn] = useState('');
	const [qust10, setQust10] = useState(2);
	const [qust10resn, setQust10Resn] = useState('');
	const [qust11, setQust11] = useState('');
	const [qust12, setQust12] = useState('');
	const [qust13, setQust13] = useState(0);
	const [qust13resn, setQust13Resn] = useState('');
	const [qust14, setQust14] = useState(2);
	const [qust15, setQust15] = useState('');
	const [qust16, setQust16] = useState(2);
	const [qust17, setQust17] = useState(2);
	const [qust18a, setQust18a] = useState('');
	const [qust18b, setQust18b] = useState('');
	const [qust18c, setQust18c] = useState('');
	const [qust18d, setQust18d] = useState('');
	const [qust18e, setQust18e] = useState('');
	const [qust18f, setQust18f] = useState('');

	const [photo01a, setPhoto01a] = useState(null);
	const [photo01b, setPhoto01b] = useState(null);
	const [photo01c, setPhoto01c] = useState(null);
	const [photo01d, setPhoto01d] = useState(null);
	const [photo02a, setPhoto02a] = useState(null);
	const [photo02b, setPhoto02b] = useState(null);
	const [photo02c, setPhoto02c] = useState(null);
	const [photo02d, setPhoto02d] = useState(null);
	const [photo03a, setPhoto03a] = useState(null);
	const [photo03b, setPhoto03b] = useState(null);
	const [photo03c, setPhoto03c] = useState(null);
	const [photo03d, setPhoto03d] = useState(null);
	const [photo04a, setPhoto04a] = useState(null);
	const [photo04b, setPhoto04b] = useState(null);
	const [photo04c, setPhoto04c] = useState(null);
	const [photo04d, setPhoto04d] = useState(null);
	const [photo05a, setPhoto05a] = useState(null);
	const [photo05b, setPhoto05b] = useState(null);
	const [photo05c, setPhoto05c] = useState(null);
	const [photo05d, setPhoto05d] = useState(null);
	const [photo06a, setPhoto06a] = useState(null);
	const [photo06b, setPhoto06b] = useState(null);
	const [photo06c, setPhoto06c] = useState(null);
	const [photo06d, setPhoto06d] = useState(null);
	const [photo07a, setPhoto07a] = useState(null);
	const [photo07b, setPhoto07b] = useState(null);
	const [photo07c, setPhoto07c] = useState(null);
	const [photo07d, setPhoto07d] = useState(null);
	const [photo08a, setPhoto08a] = useState(null);
	const [photo08b, setPhoto08b] = useState(null);
	const [photo08c, setPhoto08c] = useState(null);
	const [photo08d, setPhoto08d] = useState(null);
	const [photo09a, setPhoto09a] = useState(null);
	const [photo09b, setPhoto09b] = useState(null);
	const [photo09c, setPhoto09c] = useState(null);
	const [photo09d, setPhoto09d] = useState(null);
	const [photo10a, setPhoto10a] = useState(null);
	const [photo10b, setPhoto10b] = useState(null);
	const [photo10c, setPhoto10c] = useState(null);
	const [photo10d, setPhoto10d] = useState(null);
	const [photo11a, setPhoto11a] = useState(null);
	const [photo11b, setPhoto11b] = useState(null);
	const [photo11c, setPhoto11c] = useState(null);
	const [photo11d, setPhoto11d] = useState(null);
	const [photo12a, setPhoto12a] = useState(null);
	const [photo12b, setPhoto12b] = useState(null);
	const [photo12c, setPhoto12c] = useState(null);
	const [photo12d, setPhoto12d] = useState(null);
	const [photo13a, setPhoto13a] = useState(null);
	const [photo13b, setPhoto13b] = useState(null);
	const [photo13c, setPhoto13c] = useState(null);
	const [photo13d, setPhoto13d] = useState(null);
	const [photo14a, setPhoto14a] = useState(null);
	const [photo14b, setPhoto14b] = useState(null);
	const [photo14c, setPhoto14c] = useState(null);
	const [photo14d, setPhoto14d] = useState(null);
	const [photo15a, setPhoto15a] = useState(null);
	const [photo15b, setPhoto15b] = useState(null);
	const [photo15c, setPhoto15c] = useState(null);
	const [photo15d, setPhoto15d] = useState(null);
	const [photo16a, setPhoto16a] = useState(null);
	const [photo16b, setPhoto16b] = useState(null);
	const [photo16c, setPhoto16c] = useState(null);
	const [photo16d, setPhoto16d] = useState(null);
	const [photo17a, setPhoto17a] = useState(null);
	const [photo17b, setPhoto17b] = useState(null);
	const [photo17c, setPhoto17c] = useState(null);
	const [photo17d, setPhoto17d] = useState(null);
	const [photo18a, setPhoto18a] = useState(null);
	const [photo18b, setPhoto18b] = useState(null);
	const [photo18c, setPhoto18c] = useState(null);
	const [photo18d, setPhoto18d] = useState(null);
	const [photo19a, setPhoto19a] = useState(null);
	const [photo19b, setPhoto19b] = useState(null);
	const [photo19c, setPhoto19c] = useState(null);
	const [photo19d, setPhoto19d] = useState(null);
	const [photo20a, setPhoto20a] = useState(null);
	const [photo20b, setPhoto20b] = useState(null);
	const [photo20c, setPhoto20c] = useState(null);
	const [photo20d, setPhoto20d] = useState(null);
	const [photo21a, setPhoto21a] = useState(null);
	const [photo21b, setPhoto21b] = useState(null);
	const [photo21c, setPhoto21c] = useState(null);
	const [photo21d, setPhoto21d] = useState(null);

	const [cnfm01, setCnfm01] = useState(0);
	const [cnfm02, setCnfm02] = useState(0);
	const [cnfm03, setCnfm03] = useState(0);
	const [cnfm04, setCnfm04] = useState(0);
	const [cnfm05, setCnfm05] = useState(0);
	const [cnfm06, setCnfm06] = useState(0);
	const [cnfm07, setCnfm07] = useState(0);



	useEffect(() => {
		loadUpdatePage(assignment, (err, result) => {
			if (err) {
				NotificationManager.error(err.message, 'Failed to Load Information');
				return
			} else {
				setCompany(result.data[0].company);
				setEmail(result.data[0].email);
				setMobile(result.data[0].mobile);
				setAssigned(result.data[0].assgnname);
				setKref(result.data[0].kref);
				setAddress(result.data[0].generalloc);
				setPAddress(result.data[0].address);
				setStudiosize(result.data[0].studiosize);
				setTypestudio(result.data[0].type);
				setSmokealarm(result.data[0].smoke_alarm);
				setOverlays(result.data[0].overlay);
				setCovenants(result.data[0].covenants);
				setCaveats(result.data[0].caveats);

				setQust01(result.data[0].qust01);
				setQust02(result.data[0].qust02);
				setQust02img(result.data[0].qust02img);
				setQust03(result.data[0].qust03);
				setQust04(result.data[0].qust04);
				setQust05(result.data[0].qust05);
				setQust06(result.data[0].qust06);
				setQust07(result.data[0].qust07);
				setQust0801(result.data[0].qust08a);
				setQust0802(result.data[0].qust08b);
				setQust09(result.data[0].qust09);
				setQust09Resn(result.data[0].qust09resn);
				setQust10(result.data[0].qust10);
				setQust10Resn(result.data[0].qust10resn);
				setQust11(result.data[0].qust11);
				setQust12(result.data[0].qust12);
				setQust13(result.data[0].qust13);
				setQust13Resn(result.data[0].qust13resn);
				setQust14(result.data[0].qust14);
				setQust15(result.data[0].qust15);
				setQust16(result.data[0].qust16);
				setQust17(result.data[0].qust17);
				setQust18a(result.data[0].qust18a);
				setQust18b(result.data[0].qust18b);
				setQust18c(result.data[0].qust18c);
				setQust18d(result.data[0].qust18d);
				setQust18e(result.data[0].qust18e);
				setQust18f(result.data[0].qust18f);

				setPhoto01a((result.data[0].item01a == '') ? null : result.data[0].item01a);
				setPhoto01b((result.data[0].item01b == '') ? null : result.data[0].item01b);
				setPhoto01c((result.data[0].item01c == '') ? null : result.data[0].item01c);
				setPhoto01d((result.data[0].item01d == '') ? null : result.data[0].item01d);
				setPhoto02a((result.data[0].item02a == '') ? null : result.data[0].item02a);
				setPhoto02b((result.data[0].item02b == '') ? null : result.data[0].item02b);
				setPhoto02c((result.data[0].item02c == '') ? null : result.data[0].item02c);
				setPhoto02d((result.data[0].item02d == '') ? null : result.data[0].item02d);
				setPhoto03a((result.data[0].item03a == '') ? null : result.data[0].item03a);
				setPhoto03b((result.data[0].item03b == '') ? null : result.data[0].item03b);
				setPhoto03c((result.data[0].item03c == '') ? null : result.data[0].item03c);
				setPhoto03d((result.data[0].item03d == '') ? null : result.data[0].item03d);
				setPhoto04a((result.data[0].item04a == '') ? null : result.data[0].item04a);
				setPhoto04b((result.data[0].item04b == '') ? null : result.data[0].item04b);
				setPhoto04c((result.data[0].item04c == '') ? null : result.data[0].item04c);
				setPhoto04d((result.data[0].item04d == '') ? null : result.data[0].item04d);
				setPhoto05a((result.data[0].item05a == '') ? null : result.data[0].item05a);
				setPhoto05b((result.data[0].item05b == '') ? null : result.data[0].item05b);
				setPhoto05c((result.data[0].item05c == '') ? null : result.data[0].item05c);
				setPhoto05d((result.data[0].item05d == '') ? null : result.data[0].item05d);
				setPhoto06a((result.data[0].item06a == '') ? null : result.data[0].item06a);
				setPhoto06b((result.data[0].item06b == '') ? null : result.data[0].item06b);
				setPhoto06c((result.data[0].item06c == '') ? null : result.data[0].item06c);
				setPhoto06d((result.data[0].item06d == '') ? null : result.data[0].item06d);
				setPhoto07a((result.data[0].item07a == '') ? null : result.data[0].item07a);
				setPhoto07b((result.data[0].item07b == '') ? null : result.data[0].item07b);
				setPhoto07c((result.data[0].item07c == '') ? null : result.data[0].item07c);
				setPhoto07d((result.data[0].item07d == '') ? null : result.data[0].item07d);
				setPhoto08a((result.data[0].item08a == '') ? null : result.data[0].item08a);
				setPhoto08b((result.data[0].item08b == '') ? null : result.data[0].item08b);
				setPhoto08c((result.data[0].item08c == '') ? null : result.data[0].item08c);
				setPhoto08d((result.data[0].item08d == '') ? null : result.data[0].item08d);
				setPhoto09a((result.data[0].item09a == '') ? null : result.data[0].item09a);
				setPhoto09b((result.data[0].item09b == '') ? null : result.data[0].item09b);
				setPhoto09c((result.data[0].item09c == '') ? null : result.data[0].item09c);
				setPhoto09d((result.data[0].item09d == '') ? null : result.data[0].item09d);
				setPhoto10a((result.data[0].item10a == '') ? null : result.data[0].item10a);
				setPhoto10b((result.data[0].item10b == '') ? null : result.data[0].item10b);
				setPhoto10c((result.data[0].item10c == '') ? null : result.data[0].item10c);
				setPhoto10d((result.data[0].item10d == '') ? null : result.data[0].item10d);
				setPhoto11a((result.data[0].item11a == '') ? null : result.data[0].item11a);
				setPhoto11b((result.data[0].item11b == '') ? null : result.data[0].item11b);
				setPhoto11c((result.data[0].item11c == '') ? null : result.data[0].item11c);
				setPhoto11d((result.data[0].item11d == '') ? null : result.data[0].item11d);
				setPhoto12a((result.data[0].item12a == '') ? null : result.data[0].item12a);
				setPhoto12b((result.data[0].item12b == '') ? null : result.data[0].item12b);
				setPhoto12c((result.data[0].item12c == '') ? null : result.data[0].item12c);
				setPhoto12d((result.data[0].item12d == '') ? null : result.data[0].item12d);
				setPhoto13a((result.data[0].item13a == '') ? null : result.data[0].item13a);
				setPhoto13b((result.data[0].item13b == '') ? null : result.data[0].item13b);
				setPhoto13c((result.data[0].item13c == '') ? null : result.data[0].item13c);
				setPhoto13d((result.data[0].item13d == '') ? null : result.data[0].item13d);
				setPhoto14a((result.data[0].item14a == '') ? null : result.data[0].item14a);
				setPhoto14b((result.data[0].item14b == '') ? null : result.data[0].item14b);
				setPhoto14c((result.data[0].item14c == '') ? null : result.data[0].item14c);
				setPhoto14d((result.data[0].item14d == '') ? null : result.data[0].item14d);
				setPhoto15a((result.data[0].item15a == '') ? null : result.data[0].item15a);
				setPhoto15b((result.data[0].item15b == '') ? null : result.data[0].item15b);
				setPhoto15c((result.data[0].item15c == '') ? null : result.data[0].item15c);
				setPhoto15d((result.data[0].item15d == '') ? null : result.data[0].item15d);
				setPhoto16a((result.data[0].item16a == '') ? null : result.data[0].item16a);
				setPhoto16b((result.data[0].item16b == '') ? null : result.data[0].item16b);
				setPhoto16c((result.data[0].item16c == '') ? null : result.data[0].item16c);
				setPhoto16d((result.data[0].item16d == '') ? null : result.data[0].item16d);
				setPhoto17a((result.data[0].item17a == '') ? null : result.data[0].item17a);
				setPhoto17b((result.data[0].item17b == '') ? null : result.data[0].item17b);
				setPhoto17c((result.data[0].item17c == '') ? null : result.data[0].item17c);
				setPhoto17d((result.data[0].item17d == '') ? null : result.data[0].item17d);
				setPhoto18a((result.data[0].item18a == '') ? null : result.data[0].item18a);
				setPhoto18b((result.data[0].item18b == '') ? null : result.data[0].item18b);
				setPhoto18c((result.data[0].item18c == '') ? null : result.data[0].item18c);
				setPhoto18d((result.data[0].item18d == '') ? null : result.data[0].item18d);
				setPhoto19a((result.data[0].item19a == '') ? null : result.data[0].item19a);
				setPhoto19b((result.data[0].item19b == '') ? null : result.data[0].item19b);
				setPhoto19c((result.data[0].item19c == '') ? null : result.data[0].item19c);
				setPhoto19d((result.data[0].item19d == '') ? null : result.data[0].item19d);
				setPhoto20a((result.data[0].item20a == '') ? null : result.data[0].item20a);
				setPhoto20b((result.data[0].item20b == '') ? null : result.data[0].item20b);
				setPhoto20c((result.data[0].item20c == '') ? null : result.data[0].item20c);
				setPhoto20d((result.data[0].item20d == '') ? null : result.data[0].item20d);
				setPhoto21a((result.data[0].item21a == '') ? null : result.data[0].item21a);
				setPhoto21b((result.data[0].item21b == '') ? null : result.data[0].item21b);
				setPhoto21c((result.data[0].item21c == '') ? null : result.data[0].item21c);
				setPhoto21d((result.data[0].item21d == '') ? null : result.data[0].item21d);

				setCnfm01(result.data[0].cnfm01);
				setCnfm02(result.data[0].cnfm02);
				setCnfm03(result.data[0].cnfm03);
				setCnfm04(result.data[0].cnfm04);
				setCnfm05(result.data[0].cnfm05);
				setCnfm06(result.data[0].cnfm06);
				setCnfm07(result.data[0].cnfm07);
			}
		});
	}, []);

	function changeTabData(value) {
		setTabData(value);
	}

	return (
		<>
			<Header />
			<Sidebar />
			<div className="content-wrapper">
				{/* Content Header (Page header) */}
				<section className="content-header">
					<div className="container-fluid">
						<div className="row mb-2">
							<div className="col-sm-6">
								<h1>Assignment Form</h1>
							</div>
							<div className="col-sm-6">
								<ol className="breadcrumb float-sm-right">
									<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
									<li className="breadcrumb-item active">Assignment Form</li>
								</ol>
							</div>
						</div>
					</div>{/* /.container-fluid */}
				</section>
				<section className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-12">
								<div className="card card-default">
									<div className="card-body p-0">

										<div className="bs-stepper">
											<div className="bs-stepper-header" role="tablist">
												<div className="step" data-target="#initial-part">
													<button type="button" className={(tabData === 1) ? 'onhover step-trigger' : 'step-trigger'} onClick={() => { changeTabData(1) }} role="tab" aria-controls="initial-part" id="initial-part-trigger">
														<span className="bs-stepper-circle">1</span>
														<span className="bs-stepper-label">Initial Info</span>
													</button>
												</div>
												<div className="line" />
												<div className="step" data-target="#information-basic">
													<button type="button" className={(tabData === 2) ? 'onhover step-trigger' : 'step-trigger'} onClick={() => { changeTabData(2) }} role="tab" aria-controls="information-basic" id="information-basic-trigger">
														<span className="bs-stepper-circle">2</span>
														<span className="bs-stepper-label">Basic Information</span>
													</button>
												</div>
												<div className="line" />
												<div className="step" data-target="#information-more">
													<button type="button" className={(tabData === 3) ? 'onhover step-trigger' : 'step-trigger'} onClick={() => { changeTabData(3) }} role="tab" aria-controls="information-more" id="information-more-trigger">
														<span className="bs-stepper-circle">3</span>
														<span className="bs-stepper-label">Checklist</span>
													</button>
												</div>
												<div className="line" />
												<div className="step" data-target="#information-photos">
													<button type="button" className={(tabData === 4) ? 'onhover step-trigger' : 'step-trigger'} onClick={() => { changeTabData(4) }} role="tab" aria-controls="information-photos" id="information-photos-trigger">
														<span className="bs-stepper-circle">4</span>
														<span className="bs-stepper-label">Photos</span>
													</button>
												</div>
												<div className="line" />
												<div className="step" data-target="#information-checkbox">
													<button type="button" className={(tabData === 5) ? 'onhover step-trigger' : 'step-trigger'} onClick={() => { changeTabData(5) }} role="tab" aria-controls="information-checkbox" id="information-checkbox-trigger">
														<span className="bs-stepper-circle">5</span>
														<span className="bs-stepper-label">Confirmation</span>
													</button>
												</div>
											</div>
											<div className="bs-stepper-content my-5">
												<div id="initial-part" className={(tabData !== 1) ? 'col-12 content' : 'col-12'} role="tabpanel" aria-labelledby="initial-part-trigger">
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="company">Comapany</label>
																<input type="text" className="form-control" defaultValue={company} readOnly={true} name="company" />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="email">Email</label>
																<input type="email" className="form-control" defaultValue={email} readOnly={true} name="email" />
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="mobile">Mobile</label>
																<input type="text" className="form-control" defaultValue={mobile} readOnly={true} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="user">Assigned Junior Architect</label>
																<input type="text" className="form-control" defaultValue={assgnname} readOnly={true} />
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="mobile">KREF</label>
																<input type="text" className="form-control" defaultValue={kref} readOnly={true} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label htmlFor="address">Proposed Studio Address</label>
																<input type="text" className="form-control" defaultValue={address} readOnly={true} />
															</div>
														</div>
													</div>
													<div className="text-right">
														<button className="btn btn-primary" onClick={() => { changeTabData(2) }}>Next</button>
													</div>
												</div>

												<div id="information-basic" className={(tabData !== 2) ? 'col-12 content' : 'col-12'} role="tabpanel" aria-labelledby="information-basic-trigger">
													<div className="row">
														<div className="col-md-12">
															<div className="form-group">
																<label>Home/Studio Address</label>
																<input type="text" className="form-control" defaultValue={paddress} />
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label>Studio Size Proposed</label>
																<input type="text" className="form-control" defaultValue={studiosize} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label>Type of Studio</label>
																<input type="email" className="form-control" defaultValue={typestudio} />
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label>Interconnected Smoke Alarm</label>
																<input type="text" className="form-control" defaultValue={smokealarm} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label>Overlays Identified</label>
																<input type="text" className="form-control" defaultValue={overlays} />
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-6">
															<div className="form-group">
																<label>Covenants Identified</label>
																<input type="text" className="form-control" defaultValue={covenants} />
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<label>Caveats Identified</label>
																<input type="text" className="form-control" defaultValue={caveats} />
															</div>
														</div>
													</div>
													<div className="text-right">
														<button className="btn btn-warning mr-2" onClick={() => { changeTabData(1) }}>Previous</button>
														<button className="btn btn-primary" onClick={() => { changeTabData(3) }}>Next</button>
													</div>
												</div>

												<div id="information-more" className={(tabData !== 3) ? 'col-12 content' : 'col-12'} role="tabpanel" aria-labelledby="information-more-trigger">
													<div className="form-group">
														<p className="lh-25">1.	To make a call to AOD office contact number 10 min before you reach site, so that carer is contacted and informed your arrival.</p>
														<div className="radio-group">
															<input type="radio" id="question011" value="yes" name="radio-01" checked={qust01 == 1} />
															<label htmlFor="question011">Yes</label>
															<input type="radio" id="question012" value="no" name="radio-01" checked={qust01 == 0} />
															<label htmlFor="question012">No</label>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">2.	Site entry time recorded by front side image of the site (<i>time will taken at time of image upload)</i></p>
														<div className="radio-group">
															<input type="radio" id="question021" value="yes" name="radio-02" checked={qust02 == 1} />
															<label htmlFor="question021">Yes</label>
															<input type="radio" id="question022" value="no" name="radio-02" checked={qust02 == 0} />
															<label htmlFor="question022">No</label>
														</div>

														<div className={"input-group " + (qust02 == 1 ? null : "d-none")} >
															<div className={(qust02img != null) ? null : "d-none"} >
																<img alt="not fount" className="upload-img" src={qust02img} />
															</div>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">3.	Please confirm that you have followed the Covid checklist and mask is worn and distance was maintained at all times on site.</p>
														<div className="radio-group">
															<input type="radio" id="question031" value="yes" name="radio-03" checked={qust03 == 1} />
															<label htmlFor="question031">Yes</label>
															<input type="radio" id="question032" value="no" name="radio-03" checked={qust03 == 0} />
															<label htmlFor="question032">No</label>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">4.	Is carer present on site showing you access to rear garden (<i>Please call AOD if carer is not present and access door is shut</i>)</p>
														<div className="radio-group">
															<input type="radio" id="question041" value="yes" name="radio-04" checked={qust04 == 1} />
															<label htmlFor="question041">Yes</label>
															<input type="radio" id="question042" value="no" name="radio-04" checked={qust04 == 0} />
															<label htmlFor="question042">No</label>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">5.	Must introduce yourself and inform carer that you are here for Site planning for Kids Under cover studio/bunglow and will be assessing and measuring only outside premises for 1 hr (<i>Please call AOD if there is a trouble in communication</i>)</p>
														<div className="radio-group">
															<input type="radio" id="question051" value="yes" name="radio-05" checked={qust05 == 1} />
															<label htmlFor="question051">Yes</label>
															<input type="radio" id="question052" value="no" name="radio-05" checked={qust05 == 0} />
															<label htmlFor="question052">No</label>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">6.	Are the pets inside while you did the site measure?</p>
														<div className="radio-group">
															<input type="radio" id="question061" value="yes" name="radio-06" checked={qust06 == 1} />
															<label htmlFor="question061">Yes</label>
															<input type="radio" id="question062" value="no" name="radio-06" checked={qust06 == 0} />
															<label htmlFor="question062">No</label>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">7.	Please confirm that No contact has been made to Assets and items on site while measuring.</p>
														<div className="radio-group">
															<input type="radio" id="question071" value="yes" name="radio-07" checked={qust07 == 1} />
															<label htmlFor="question071">Yes</label>
															<input type="radio" id="question072" value="no" name="radio-07" checked={qust07 == 0} />
															<label htmlFor="question072">No</label>
														</div>
													</div>

													<div className="row">
														<div className="col-md-12">
															<div className="form-group">
																<p>8.	Potential installation issues:<br /><br />
																	<i>Please list any issues identified by the carer or yourself, including concerns about household, safety, structures or impediments to site.</i></p>
																<textarea type="text" className="form-control" defaultValue={qust0801} />
																<p className="mt-4"><i>If there is a structure or items that needs to be removed by the carer, please advise if this was discussed at the time of the appointment.</i></p>
																<textarea type="text" className="form-control" defaultValue={qust0802} />

															</div>
														</div>
													</div>


													<div className="form-group">
														<p className="lh-25">9.	To fit in the studio location whether the clothesline needs to be relocated /removed ? and is the carer ready to remove the clothesline?</p>
														<div className="radio-group">
															<input type="radio" id="question091" value="yes" name="radio-09" checked={qust09 == 1} />
															<label htmlFor="question091">Yes</label>
															<input type="radio" id="question092" value="no" name="radio-09" checked={qust09 == 0} />
															<label htmlFor="question092">No</label>
															<textarea type="text" className={"form-control " + (qust09 == 0 ? null : "d-none")} defaultValue={qust09resn} />
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">10.	Make sure to place the proposed studio without hindering any existing shed (if it is present on site) - <i>If No - List reasons why the shed needs removal and if it was discussed with carer and was asked if they are ready to remove the shed.</i></p>
														<div className="radio-group">
															<input type="radio" id="question101" value="yes" name="radio-10" checked={qust10 == 1} />
															<label htmlFor="question101">Yes</label>
															<input type="radio" id="question102" value="no" name="radio-10" checked={qust10 == 0} />
															<label htmlFor="question102">No</label>
															<textarea type="text" className={"form-control " + (qust10 === 0 ? null : "d-none")} defaultValue={qust10resn} />
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">11.	Studio positioning relative to site overall:</p>
														<textarea type="text" className="form-control" defaultValue={qust11} />
													</div>

													<div className="form-group">
														<p className="lh-25">12.	Site Access:</p>
														<textarea type="text" className="form-control" defaultValue={qust12} />
													</div>

													<div className="form-group">
														<p className="lh-25">13.	Site fall:</p>
														<select className="form-control" defaultValue={qust13}>
															<option value={0} >Select Fall</option>
															<option value={1}>Relatively Flat</option>
															<option value={2}>Slight Slope</option>
															<option value={3}>Steep Slope</option>
														</select><br />
														<input type="text" className={"form-control " + ((qust13 == 2 || qust13 == 3) ? null : "d-none")} defaultValue={qust13resn} placeholder="Measurements" />
													</div>

													<div className="form-group">
														<p className="lh-25">14.	Can the studio be craned in – either the kit off the truck or the full studio off a truck?</p>
														<div className="radio-group">
															<input type="radio" id="question141" value="yes" name="radio-14" checked={qust14 == 1} />
															<label htmlFor="question141">Yes</label>
															<input type="radio" id="question142" value="no" name="radio-14" checked={qust14 == 0} />
															<label htmlFor="question142">No</label>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">15.	Site main Access Width: (in mm)</p>
														<input type="text" className="form-control" defaultValue={qust15} />
													</div>

													<div className="form-group">
														<p className="lh-25">16.	Overhead power lines:</p>
														<div className="radio-group">
															<input type="radio" id="question161" value="yes" name="radio-16" checked={qust16 == 1} />
															<label htmlFor="question161">Yes</label>
															<input type="radio" id="question162" value="no" name="radio-16" checked={qust16 == 0} />
															<label htmlFor="question162">No</label>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">17.	Other overhead cables:</p>
														<div className="radio-group">
															<input type="radio" id="question171" value="yes" name="radio-17" checked={qust17 == 1} />
															<label htmlFor="question171">Yes</label>
															<input type="radio" id="question172" value="no" name="radio-17" checked={qust17 == 0} />
															<label htmlFor="question172">No</label>
														</div>
													</div>

													<div className="form-group">
														<p className="lh-25">18.	Services: Measurements from proposed location to be drawn and recorded in site plan</p>
														<i>Electrical Switchboard Location (in mm)</i>
														<input type="text" className="form-control" defaultValue={qust18a} /><br />
														<i>Main Water Point Location (in mm)</i>
														<input type="text" className="form-control" defaultValue={qust18b} /><br />
														<i>Sewer Point Location (in mm)</i>
														<input type="text" className="form-control" defaultValue={qust18c} /><br />
														<i>Nearest Tap to Proposed Studio (in mm)</i>
														<input type="text" className="form-control" defaultValue={qust18d} /><br />
														<i>Nearest Down Pipe (in mm)</i>
														<input type="text" className="form-control" defaultValue={qust18e} /><br />
														<i>Hot Water Service (in mm)</i>
														<input type="text" className="form-control" defaultValue={qust18f} />
													</div>


													<div className="text-right">
														<button className="btn btn-warning mr-2" onClick={() => { changeTabData(2) }}>Previous</button>
														<button className="btn btn-primary" onClick={() => { changeTabData(4) }}>Next</button>
													</div>
												</div>

												<div id="information-basic" className={(tabData !== 4) ? 'content' : null} role="tabpanel" aria-labelledby="information-basic-trigger">
													<div className="col-md-12">

														<div className="col-md-12">
															<p>1.	Street View photos for the build crew to see the access to the street and to the house</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo01a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo01a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo01b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo01b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo01c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo01c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo01d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo01d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>2.	Driveway</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo02a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo02a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo02b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo02b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo02c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo02c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo02d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo02d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>3.	Front or side access</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo03a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo03a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo03b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo03b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo03c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo03c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo03d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo03d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>4.	Garage</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo04a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo04a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo04b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo04b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo04c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo04c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo04d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo04d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">

														<div className="col-md-12">
															<p>5.	Front garden/area photos</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo05a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo05a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo05b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo05b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo05c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo05c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo05d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo05d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>6.	Gate/Entry to rear yard</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo06a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo06a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo06b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo06b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo06c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo06c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo06d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo06d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>7.	All utilities location</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo07a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo07a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo07b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo07b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo07c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo07c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo07d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo07d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>8.	Electrical switchboard/ MSB location</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo08a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo08a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo08b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo08b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo08c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo08c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo08d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo08d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>9.	Main Water point location</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo09a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo09a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo09b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo09b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo09c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo09c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo09d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo09d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>10.	Sewer point and inspect shaft if visible on site location</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo10a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo10a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo10b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo10b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo10c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo10c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo10d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo10d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>11.	Nearest tap to proposed studio</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo11a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo11a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo11b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo11b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo11c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo11c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo11d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo11d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>12.	Nearest Down Pipe</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo12a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo12a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo12b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo12b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo12c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo12c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo12d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo12d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>13.	Hot water service</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo13a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo13a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo13b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo13b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo13c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo13c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo13d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo13d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>14.	PROPOSED STUDIO LOCATION</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo14a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo14a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo14b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo14b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo14c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo14c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo14d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo14d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>15.	Trees in rear yard (state if they need trimming. Or cutting down)</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo15a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo15a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo15b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo15b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo15c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo15c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo15d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo15d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>16.	Clothesline</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo16a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo16a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo16b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo16b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo16c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo16c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo16d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo16d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>17.	Rear yard photos from all sides</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo17a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo17a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo17b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo17b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo17c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo17c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo17d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo17d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>18.	Any sheds or storage areas at rear yard if applicable</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo18a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo18a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo18b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo18b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo18c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo18c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo18d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo18d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>19.	Side yard photos if applicable</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo19a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo19a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo19b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo19b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo19c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo19c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo19d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo19d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>20.	Side and back fences photos</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo20a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo20a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo20b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo20b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo20c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo20c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo20d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo20d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="col-md-12">
														<div className="col-md-12">
															<p>21.	Site  plan</p>
														</div>
														<div className="col-md-12">
															<div className="row">
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo21a != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo21a} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo21b != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo21b} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo21c != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo21c} />
																		</div>
																	</div>
																</div>
																<div className="col-md-12 col-lg-3">
																	<div className="d-contain">
																		<div className={(photo21d != null) ? "d-row" : "d-none"} >
																			<img alt="not found" className="upload-img d-cell w-100" src={photo21d} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<hr />

													<div className="text-right mt-20">
														<button className="btn btn-warning mr-2" onClick={() => { changeTabData(3) }}>Previous</button>
														<button className="btn btn-primary" onClick={() => { changeTabData(5) }}>Next</button>
													</div>
												</div>

												<div id="information-basic" className={(tabData !== 5) ? 'content' : null} role="tabpanel" aria-labelledby="information-basic-trigger">
													<div className="d-flex">
														<input type="checkbox" id="checkbox01" checked={cnfm01 == 1} className="regular-checkbox" />
														<label htmlFor="checkbox01">To maintain the carers confidentiality at all times however please raise any comments of concern you may have with AOD.</label>
													</div>

													<div className="d-flex">
														<input type="checkbox" id="checkbox02" checked={cnfm02 == 1} className="regular-checkbox" />
														<label htmlFor="checkbox02">Be professional to the carer all the times and no non work related conversations with carer or anyone else on the premises.</label>
													</div>

													<div className="d-flex">
														<input type="checkbox" id="checkbox03" checked={cnfm03 == 1} className="regular-checkbox" />
														<label htmlFor="checkbox03">Please ensure that the proposed studio is placed within 6 metres to the services/utilities to the property (where possible)Where it is not possible please measure the exact distance from all utilities to the proposed studio location in the sketch site plan.</label>
													</div>

													<div className="d-flex">
														<input type="checkbox" id="checkbox04" checked={cnfm04 == 1} className="regular-checkbox" />
														<label htmlFor="checkbox04">Please ensure that when considering the studio proposed location that the six-star energy rating is maintained.</label>
													</div>

													<div className="d-flex">
														<input type="checkbox" id="checkbox05" checked={cnfm05 == 1} className="regular-checkbox" />
														<label htmlFor="checkbox05">Please ensure that when considering the studio location, neighbouring fences and their heights is considered to ensure privacy is maintained for both parties</label>
													</div>

													<div className="d-flex">
														<input type="checkbox" id="checkbox06" checked={cnfm06 == 1} className="regular-checkbox" />
														<label htmlFor="checkbox06">Where possible, please orientate studio to maximise backyard space.</label>
													</div>

													<div className="d-flex">
														<input type="checkbox" id="checkbox07" checked={cnfm07 == 1} className="regular-checkbox" />
														<label htmlFor="checkbox07">For the studio positioning, Please check where the easement is running and as per regulation give enough setback.</label>
													</div>

													<div className="text-right">
														<button className="btn btn-warning mr-2" onClick={() => { changeTabData(4) }}>Previous</button>
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<Footer />
		</>
	)
}

export default ViewAssignment