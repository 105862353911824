import { Link } from "react-router-dom"
import { useState } from 'react';

import { createUser } from '../../Apis';
import { NotificationManager } from 'react-notifications';

import Footer from "../components/Footer"
import Header from "../components/Header"
import Sidebar from "../components/Sidebar"

const CreateUser = () => {
	const initialValues = [{ username: '', name: '', password: '' }];
	const [errors, setErrors] = useState(initialValues);
	const [loading, setLoading] = useState(false);

	const [username, setUsername] = useState('');
	const [name, setName] = useState('');
	const [password, setPassword] = useState('');


	const handleSigninSubmit = (e) => {
		e.preventDefault();
		let newErr = [...initialValues];
		let errflag = false;
		setErrors(newErr);
		if (username === '') {
			newErr['username'] = 'Please enter Username for login';
			errflag = true;
		}
		if (name === '') {
			newErr['name'] = 'Please enter New User Name';
			errflag = true;
		}
		if (password === '') {
			newErr['password'] = 'Please provide a User login Password';
			errflag = true;
		}


		if (errflag == false) {
			setLoading(true);
			createUser(username, name, password, (err, result) => {
				if (err) {
					NotificationManager.success(err.message, 'Failed to Create New User');
					setLoading(false);
					setErrors(newErr);
					return
				} else {
					NotificationManager.success('Have successfully created new user', 'User Created');
					setName('');
					setUsername('');
					setPassword('');

					setLoading(false);
				}
			})
		} else {
			setErrors(newErr);
		}
	}

	const toInputUppercase = e => {
		e.target.value = ("" + e.target.value).toLowerCase();
	};

	return (
		<>
			<Header />
			<Sidebar />
			<div className="content-wrapper">
				{/* Content Header (Page header) */}
				<section className="content-header">
					<div className="container-fluid">
						<div className="row mb-2">
							<div className="col-sm-6">
								<h1>Create New User</h1>
							</div>
							<div className="col-sm-6">
								<ol className="breadcrumb float-sm-right">
									<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
									<li className="breadcrumb-item active">Create New User</li>
								</ol>
							</div>
						</div>
					</div>{/* /.container-fluid */}
				</section>
				<section className="content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-12">
								<div className="card card-default">
									<div>
										<div className="card-header">
											<h3 className="card-title">Provide New User Information</h3>
										</div>
										<form onSubmit={handleSigninSubmit}>
											<div className="card-body">
												<div className="col-md-6">
													<div className="form-group">
														<label htmlFor="username">Username</label>
														<input type="text" className="form-control" id="username" placeholder="Enter Username" value={username} onInput={toInputUppercase} onChange={e => setUsername(e.target.value.toLowerCase().replace(/ /g, ''))} maxLength="15" />
														<p className="error mb-0">{errors.username}</p>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label htmlFor="name">Name</label>
														<input type="text" className="form-control" id="name" placeholder="Enter Name" value={name} onChange={e => setName(e.target.value)} maxLength="50" />
														<p className="error mb-0">{errors.name}</p>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group">
														<label htmlFor="password">Password</label>
														<input type="text" className="form-control" id="password" placeholder="Enter Password for the User" value={password} onChange={e => setPassword(e.target.value)} maxLength="25" />
														<p className="error mb-0">{errors.password}</p>
													</div>
												</div>

											</div>
											<div className="card-footer">
												<p className="error mb-0">{errors.err}</p>
												<button type="submit" className="btn btn-primary" disabled={loading ? 'disabled' : ''} >{loading && <i className="fas fa-spinner fa-spin"></i>} Submit</button>
											</div>

										</form>
									</div>

								</div>
							</div>
						</div>
					</div>

				</section>
			</div>
			<Footer />
		</>
	)
}

export default CreateUser