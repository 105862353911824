import { Link, useLocation } from 'react-router-dom'

const Sidebar = () => {
    const pathname = useLocation().pathname

    const name = localStorage.getItem('aod_name');
    const role = localStorage.getItem('aod_role');

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* Brand Logo */}
            <a href="index3.html" className="brand-link">
                <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                <span className="brand-text font-weight-light">Ark of Design</span>
            </a>
            {/* Sidebar */}
            <div className="sidebar">
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src="dist/img/user-image.png" className="img-circle elevation-2" alt="User Image" />
                    </div>
                    <div className="info">
                        <div className="d-block">{name}</div>
                    </div>
                </div>
                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li className="nav-item">
                            <Link to="/dashboard" className={"nav-link " + ((pathname === '/dashboard') ? 'active' : null)}>
                                <i className="nav-icon fas fa-tachometer-alt" />
                                <p>Dashboard</p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/assignments" className={"nav-link " + ((pathname === '/assignments' || pathname === '/create-assignments') ? 'active' : null)}>
                                <i className="nav-icon fas fa-th" />
                                <p>Assignments</p>
                            </Link>
                        </li>
                        <li className={"nav-item " + (role == 0 ? '' : 'd-none')} >
                            <Link to="/user-list" className={"nav-link " + ((pathname === '/user-list') ? 'active' : null)}>
                                <i className="nav-icon fas fa-users" />
                                <p>Users List</p>
                            </Link>
                        </li>

                    </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
        </aside >

    )
}

export default Sidebar